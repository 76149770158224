import { useRequest } from "ahooks";
import orderBy from "lodash/orderBy";
import Papa from "papaparse";
import { Component, h } from "preact";
import { useEffect } from "preact/hooks";
import exhibitorsCSV from "raw-loader!../../data/exhibitors.csv";
import poiCSV from "raw-loader!../../data/poi.csv";
import { PureComponent } from "react";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { days, program } from "../../data/program";
import config from "../config";
import { makeParcours } from "../core";
import MapModule from "../core/map";
import BubbleCount from "../screens/home/components/BubbleCount";
import HomeGrid from "../screens/home/components/HomeGrid";
import { MultiIframeScreen } from "../screens/MultiIframeScreen";
import NotificationsScreen, {
  NotificationsBubbleCount,
} from "../screens/NotificationsScreen";
import { QuizScreen } from "../screens/QuizScreen";
import ScreenListScreen from "../screens/ScreenListScreen";
import informations from "../templates/informations.html";
import { Exhibitors } from "./Exhibitors";
import { Favorites } from "./Favorites";
import { Notes } from "./Notes";
import Parcours from "./Parcours";
import { Program } from "./Program";
import PWAPrompt from "./react-ios-pwa-prompt";
import SearchPane from "./search-pane";

let exhibitors = Papa.parse(exhibitorsCSV, {
  header: true,
  skipEmptyLines: true,
})
  .data.filter((ex) => ex.name)
  .map((ex) => ({
    ...ex,
    key: "exposant-" + ex.name.replace(/[^0-9a-zA-Z]/g, "_"),
    id: "exposant-" + ex.name.replace(/[^0-9a-zA-Z]/g, "_"),
    picto: (ex.picto || "").replace(".png", ".svg"),
    location: ex.location || ex.locationNumber,
    stands: (ex.locationId || "")
      .split(",")
      .map((s) => s.trim().replace(" ", "")),
  }));

exhibitors = orderBy(exhibitors, (ex) => ex.name.toLowerCase());

let pois = Papa.parse(poiCSV, {
  header: true,
  skipEmptyLines: true,
})
  .data.filter((el) => el.name)
  .map((el) => ({
    ...el,
    key: "poi-" + (el.locationId + " " + el.name).replace(/[^0-9a-zA-Z]/g, "_"),
    id: "poi-" + (el.locationId + " " + el.name).replace(/[^0-9a-zA-Z]/g, "_"),
    // stands: (el.locationId || "").split(",").map(s => s.trim().replace(" ", ""))
  }));

pois = orderBy(pois, (ex) => ex.name.toLowerCase());

// Exhibitors
// => roomId à partir de stand, T19->T33
// E30A => garder en roomId, stand à afficher en 30A

function timeToMinutes(t) {
  const [hours, minutes] = t.split("h");
  return Number(hours) * 60 + Number(minutes);
}

function checkPartnerName(name) {
  if (!name) return false;

  return exhibitors.find(
    (e) =>
      e.name && (e.name === name || e.name.toLowerCase() === name.toLowerCase())
  );
}

function isValidExhibitor(name) {
  if (checkPartnerName(name)) return true;

  return config.exhibitorNames && checkPartnerName(config.exhibitorNames[name]);
}

program.forEach((event) => {
  event.startMinutes = timeToMinutes(event.startTime);

  // Check partner
  if (event.partner) {
    event.partners = event.partner.split(" - ");
    for (let partner of event.partners) {
      if (!isValidExhibitor(partner)) {
        console.error("invalid partner", partner, "in", event.title);
      }
    }
  } else {
    event.partners = [];
  }
});

program.sort(function (a, b) {
  if (a.jour < b.jour) return -1;
  else if (a.jour > b.jour) return 1;

  if (a.startMinutes < b.startMinutes) return -1;
  else if (a.startMinutes > b.startMinutes) return 1;

  if (a.location < b.location) return -1;
  else if (a.location > b.location) return 1;

  return 0;
});

// console.log("program", program)
let parcours;
if (config.hasParcours) {
  parcours = makeParcours(exhibitors, program);
  console.log("parcours", parcours, null, 2);
}

const SidebarTitle = ({ children }) => (
  <h1 class="sidebar-header">
    {children}
    <span class="sidebar-close">
      <i class="fa fa-times"></i>
    </span>
  </h1>
);

const HomeButton = ({ id, icon, svgIcon, children }) => (
  <a class="fullscreen-menu-link" href={"#" + id} role="tab">
    {icon && <i class={"fa fa-fw fa-" + icon}></i>}
    {svgIcon && (
      <div
        className="fullscreen-menu-icon"
        dangerouslySetInnerHTML={{ __html: svgIcon }}
      />
    )}
    <div class="fullscreen-menu-link__text">{children}</div>
    <div class="fullscreen-menu-link__bg"></div>
  </a>
);

class Informations extends PureComponent {
  render() {
    const { informations } = this.props;
    return <div dangerouslySetInnerHTML={{ __html: informations }} />;
  }
}

{
  /* <div class="sidebar-tab__bubble">{favoriteCount()}</div> */
}

// const BubbleCount = ({ type }) => {
//   const fun = bubbleCounts[type];
//   if (!fun) return null;

//   return <div class="sidebar-tab__bubble">{fun()}</div>;
// };

function getScreens() {
  return [
    config.hasSearch && {
      name: "search",
      icon: "search",
      label: "Recherche",
    },
    {
      name: "exposants",
      label: "Exposants",
      svgIcon: require("!!raw-loader!../assets/images/pictos/exposants.svg")
        .default,
    },
    {
      name: "program",
      label: "Programme",
      svgIcon:
        require("!!raw-loader!../assets/images/pictos/programmeSessions.svg")
          .default,
    },
    {
      name: "map",
      label: "Voir le plan",
      svgIcon:
        require("!!raw-loader!../assets/images/pictos/programmeParcours.svg")
          .default,
    },
    config.hasParcours && {
      name: "parcours",
      label: "Parcours",
      svgIcon:
        require("!!raw-loader!../assets/images/pictos/programmeParcours.svg")
          .default,
    },
    config.favorites && {
      name: "favorites",
      label: "Favoris",
      svgIcon: require("!!raw-loader!../assets/images/pictos/favoris.svg")
        .default,
    },
    config.hasNotes && { name: "notes", label: "Notes", icon: "edit" },
    config.hasInfo && {
      name: "messages",
      label: "Informations pratiques",
      icon: "info-circle",
    },
    config.hasSite && {
      type: "url",
      name: "site",
      label: "Site du Congrès",
      url: "https://congres.experts-comptables.com/",
      svgIcon:
        require("!!raw-loader!../assets/images/pictos/programmeParcours.svg")
          .default,
      lazy: true,
    },
    config.hasPleniere && {
      type: "multi-iframe",
      name: "pleniere",
      label: "Plénières",
      icon: "comments",
      lazy: true,
      tabs: [
        {
          name: "quiz",
          label: "Quiz",
          url: "https://quiz.appcraft.events/client/",
          injectUser: true,
        },
        {
          name: "q_a",
          label: "Q&A",
          url: "https://live.appcraft.events/mPWnLdY5W1NR8I/client/HZzyT47cUbRIu0UQ1e04",
          injectUser: true,
        },
        {
          name: "tag_cloud",
          label: "Nuage de mots",
          url: "https://static.appcraft.events/word-cloud/mPWnLdY5W1NR8I/questions/N17PJj8gWWb0kF2Lg0Lf",
          injectUser: true,
        },
      ],
    },
    config.hasGrandesConf && {
      type: "quiz",
      name: "grandesconf",
      label: "Grandes conférences",
      icon: "comments-o",
      lazy: true,
    },
    config.hasAteliers && {
      type: "screen-list",
      name: "ateliers",
      label: "Ateliers",
      icon: "comments-o",
      screens: [
        {
          type: "multi-iframe",
          name: "pleniere",
          label: "Plénières",
          tabs: [
            {
              name: "quiz",
              label: "Quiz",
              url: "https://quiz.appcraft.events/client/",
              injectUser: true,
            },
            {
              name: "q_a",
              label: "Q&A",
              url: "https://live.appcraft.events/mPWnLdY5W1NR8I/client/HZzyT47cUbRIu0UQ1e04",
              injectUser: true,
            },
            {
              name: "tag_cloud",
              label: "Nuage de mots",
              url: "https://static.appcraft.events/word-cloud/mPWnLdY5W1NR8I/questions/N17PJj8gWWb0kF2Lg0Lf",
              injectUser: true,
            },
          ],
        },
        {
          type: "quiz",
          name: "grandesconf",
          label: "Salle 1",
        },
        {
          type: "quiz",
          name: "grandesconf",
          label: "Salle 2",
        },
        {
          type: "quiz",
          name: "grandesconf",
          label: "Salle 3",
        },
        {
          type: "quiz",
          name: "grandesconf",
          label: "Salle 4",
        },
        {
          type: "quiz",
          name: "grandesconf",
          label: "Salle 5",
        },
        {
          type: "quiz",
          name: "grandesconf",
          label: "Salle 6",
        },
        {
          type: "quiz",
          name: "grandesconf",
          label: "Salle 7",
        },
        {
          type: "quiz",
          name: "grandesconf",
          label: "Salle 8",
        },
        {
          type: "quiz",
          name: "grandesconf",
          label: "Salle 9",
        },
        {
          type: "quiz",
          name: "grandesconf",
          label: "Salle 10",
        },
        {
          type: "quiz",
          name: "grandesconf",
          label: "Salle 11",
        },
      ],
      lazy: true,
    },
    config.hasFlashMeeting && {
      type: "quiz",
      name: "FM",
      label: "Flash Meeting",
      icon: "comments-o",
      url: "https://live.appcraft.events/mPWnLdY5W1NR8I/client/RCD79mduqEjoZiAyyMlg",
      lazy: true,
    },
    config.hasFuzExpert && {
      type: "quiz",
      name: "fuz",
      label: "Fuz'Experts l'émission",
      icon: "comments-o",
      injectUser: true,
      requiresUser: true,
      lazy: true,
    },
    config.hasNotifs && {
      type: "notifications",
      name: "notifs",
      label: "Notifications",
      icon: "exclamation-triangle",
      lazy: true,
    },
  ].filter((v) => v);
}

//  console.log(JSON.stringify(getScreens()));

class Sidebar extends Component {
  state = {
    screen: "home",
  };
  componentDidMount = () => {
    $("#sidebar").on("touchstart", (event) => {
      if (event.touches && event.touches.length > 1) {
        //the event is multi-touch
        //you can then prevent the behavior
        event.preventDefault();
      }
    });

    MapModule.init({
      ...config,
      program,
      exhibitors,
      pois,
      days,
    });

    MapModule.initSidebar({
      onOpen: (screen) => {
        console.log("screen", screen);
        this.setState({ screen });
      },
    });

    MapModule.refreshFavoriteList = () => this.forceUpdate();
  };

  render() {
    const { screens, notifications } = this.props;
    const { screen } = this.state;
    let sidebarStyle = {};
    if (!config.sidebarVisible) {
      sidebarStyle = {
        width: 0,
        border: 0,
      };
    }

    const title = config.sidebar && config.sidebar.title;

    window.screens = screens;

    return (
      <div
        id="sidebar"
        class={
          "sidebar collapsed" + (config.isMobile ? " sidebar--mobile" : "")
        }
        style={sidebarStyle}
      >
        <div class="sidebar-tabs">
          <ul role="tablist">
            <li class="sidebar-tab sidebar-tab--home">
              <a href="#home" role="tab">
                <span
                  className={
                    screen === "home" ? "fa fa-bars" : "fa fa-arrow-left"
                  }
                />
                {/* <img src="./assets/images/pictos/accueil.png" alt="logo" /> */}
              </a>
            </li>
            {!config.isMobile && // Hide on mobile
              screens.map((screen) => (
                <li key={screen.name} class="sidebar-tab">
                  <a href={"#" + screen.name} role="tab">
                    {screen.icon && <i class={"fa fa-" + screen.icon} />}
                    {screen.svgIcon && (
                      <div
                        dangerouslySetInnerHTML={{ __html: screen.svgIcon }}
                      />
                    )}
                    {screen.type === "notifications" && (
                      <NotificationsBubbleCount
                        type={screen.name}
                        notifications={notifications}
                      />
                    )}
                    {screen.type !== "notifications" && (
                      <BubbleCount type={screen.name} />
                    )}
                  </a>
                </li>
              ))}
          </ul>
        </div>

        <div class="sidebar-content">
          <div class="sidebar-pane" id="home">
            <h1 class="sidebar-header">
              {title}
              <span class="sidebar-close">
                <i class="fa fa-times" />
              </span>
            </h1>
            <br />
            <div class="sidebar-content-text">
              {config.isMobile ? (
                <HomeGrid screens={screens} notifications={notifications} />
              ) : (
                // <div data-favorite="true">
                // 	{screens.map((screen) => (
                // 		<a
                // 			class="sidebar-menu-link"
                // 			href={screen.url || "#" + screen.name}
                // 			target={screen.url ? "_blank" : undefined}
                // 			role={screen.url ? "undefined" : "tab"}
                // 		>
                // 			{screen.label}
                // 		</a>
                // 	))}

                // 	<div style={{ display: "table", marginTop: 8 }}>
                // 		<div style={{ display: "table-row" }}>
                // 			<div
                // 				class="sidebar__button"
                // 				style={{
                // 					display: "table-cell",
                // 					verticalAlign: "middle",
                // 					textAlign: "center",
                // 					margin: 0,
                // 					width: "50%",
                // 					height: "100%",
                // 				}}
                // 				data-favorite="true"
                // 				onClick={() => MapModule.sidebar.close()}
                // 			>
                // 				Voir le plan
                // 			</div>
                // 		</div>
                // 	</div>

                /* <button style={{fontSize: 15, padding: "12px 8px", marginTop: 8}} class="sidebar__button" onClick={() => MapModule.sidebar.open('notes')}>Téléchargez vos notes</button> */

                /* <div class="bottom-logo">
                    <a href="https://www.rencontresdelofficine.org/" target="_blank"><img style="width:100%;margin-top:10px" src="./assets/images/banner.png" alt="11es Rencontres de l'Officine Logo" title={title} /></a>
                  </div> */
                // </div>
                <div class="menu--fullscreen">
                  <div class="top-logo">
                    <img
                      style="width:100%;"
                      src={require("../assets/images/banner.png").default}
                      alt="76e Congrès"
                      title="Site"
                    />
                  </div>
                  <HomeButton id="search" icon="search">
                    Recherche
                  </HomeButton>
                  <HomeButton
                    id="exposants"
                    svgIcon={require("../assets/images/pictos/exposants.svg")}
                  >
                    Exposants
                  </HomeButton>
                  <HomeButton
                    id="program"
                    svgIcon={require("../assets/images/pictos/programmeSessions.svg")}
                  >
                    Programme
                  </HomeButton>
                  {config.hasParcours && (
                    <HomeButton id="parcours" icon="map-signs">
                      Parcours
                    </HomeButton>
                  )}
                  {config.hasInfo && (
                    <HomeButton
                      id="messages"
                      svgIcon={require("../assets/images/pictos/informations.svg")}
                    >
                      Informations pratiques
                    </HomeButton>
                  )}
                </div>
              )}
            </div>
          </div>

          {config.sidebarVisible && config.favorites && (
            <div class="sidebar-pane" id="favorites">
              <SidebarTitle>Ma visite</SidebarTitle>
              <Favorites program={program} exhibitors={exhibitors} />
            </div>
          )}

          {config.sidebarVisible && config.hasSearch && (
            <div class="sidebar-pane" id="search">
              <SidebarTitle>Recherche</SidebarTitle>
              <SearchPane program={program} exhibitors={exhibitors} />
            </div>
          )}

          {config.sidebarVisible && (
            <div class="sidebar-pane" id="exposants">
              <SidebarTitle>Exposants</SidebarTitle>
              <Exhibitors exhibitors={exhibitors} />
            </div>
          )}

          {config.sidebarVisible && (
            <div class="sidebar-pane" id="program">
              <SidebarTitle>Programme</SidebarTitle>
              <Program program={program} days={days} />
            </div>
          )}

          {config.sidebarVisible && config.hasParcours && (
            <div class="sidebar-pane" id="parcours">
              <SidebarTitle>Parcours</SidebarTitle>
              <div id="parcours-list" class="sidebar-content-list">
                <Parcours parcours={parcours} />
              </div>
            </div>
          )}

          {config.sidebarVisible && config.hasInfo && (
            <div class="sidebar-pane" id="messages">
              <SidebarTitle>Informations pratiques</SidebarTitle>
              <br />
              <Informations informations={informations} />
            </div>
          )}

          {config.sidebarVisible && config.hasNotes && (
            <div class="sidebar-pane" id="notes">
              <SidebarTitle>Notes</SidebarTitle>
              <br />
              <Notes notes={MapModule.notes} program={program} />
            </div>
          )}

          {screens
            .filter((s) => s.type)
            .map((s) => {
              const { type, label, name, lazy, requiresUser } = s;
              const isActive = !lazy || screen === name;
              switch (type) {
                case "quiz": {
                  return (
                    <div class="sidebar-pane" id={s.name}>
                      <SidebarTitle>{label}</SidebarTitle>
                      {isActive && (
                        <QuizScreen
                          name={name}
                          url={s.url}
                          requiresUser={requiresUser}
                          injectUser={s.injectUser}
                          iframeProps={s.iframeProps}
                        />
                      )}
                    </div>
                  );
                }
                case "multi-iframe": {
                  return (
                    <div class="sidebar-pane" id={s.name}>
                      <SidebarTitle>{label}</SidebarTitle>
                      {isActive && (
                        <MultiIframeScreen
                          tabs={s.tabs}
                          requiresUser={requiresUser}
                          injectUser={s.injectUser}
                          iframeProps={s.iframeProps}
                        />
                      )}
                    </div>
                  );
                }
                case "screen-list": {
                  return (
                    <div class="sidebar-pane" id={s.name}>
                      <SidebarTitle>{label}</SidebarTitle>
                      {isActive && <ScreenListScreen screens={s.screens} />}
                    </div>
                  );
                }
                case "notifications": {
                  return (
                    <div class="sidebar-pane" id={s.name}>
                      <SidebarTitle>{label}</SidebarTitle>
                      {isActive && (
                        <NotificationsScreen notifications={notifications} />
                      )}
                    </div>
                  );
                }
                case "url": {
                  return null;
                }
              }
            })}
          {/* 
					{config.sidebarVisible && config.hasPleniere && (
						<div class="sidebar-pane" id="pleniere">
							<SidebarTitle>Plénières</SidebarTitle>
							{screen === "pleniere" && <QuizScreen />}
						</div>
					)}

					{config.sidebarVisible && config.hasGrandesConf && (
						<div class="sidebar-pane" id="grandesconf">
							<SidebarTitle>Grandes conférences</SidebarTitle>
							{screen === "grandesconf" && <QuizScreen />}
						</div>
					)}

					{config.sidebarVisible && config.hasAteliers && (
						<div class="sidebar-pane" id="ateliers">
							<SidebarTitle>Grandes conférences</SidebarTitle>
							{screen === "ateliers" && <QuizScreen />}
						</div>
					)}

					{config.sidebarVisible && config.hasFlashMeeting && (
						<div class="sidebar-pane" id="FM">
							<SidebarTitle>Flash Meeting</SidebarTitle>
							{screen === "FM" && <QuizScreen />}
						</div>
					)}

					{config.sidebarVisible && config.hasFuzExpert && (
						<div class="sidebar-pane" id="fuz">
							<SidebarTitle>Fuz'Experts l'émission</SidebarTitle>
							{screen === "fuz" && <QuizScreen />}
						</div>
					)} */}

          {/* {config.sidebarVisible && config.hasNotifs && (
            <div class="sidebar-pane" id="notifs">
              <SidebarTitle>Messages</SidebarTitle>
							<div class="sidebar-content-list"></div>
            </div>
          )} */}
        </div>
        {config.isMobile && (
          <PWAPrompt
            promptOnVisit={1}
            timesToShow={3}
            permanentlyHideOnDismiss={false}
            copyTitle="Ajouter à l'écran d'accueil"
            copyBody="Ce site peut être utilisé comme une application. Ajoutez-le à l'écran d'accueil pour l'utiliser en plein écran."
            copyShareButtonLabel="Appuyez sur le bouton 'Partage'"
            copyAddHomeButtonLabel="Appuyez sur 'Ajouter à l'écran d'accueil'"
            copyClosePrompt="Annuler"
          />
        )}
      </div>
    );
  }
}

let notificationHandler = console.log;
window.onNotification = (notification) => {
  console.log("notify", notification);
  notificationHandler(notification);
};

const Notif = ({ title, body, maxBodyLength = 80 }) => {
  return (
    <div className="react-toast-notification">
      <h3>{title}</h3>
      <p>
        {body.length > maxBodyLength
          ? `${body.slice(0, maxBodyLength - 3)}...`
          : body}
      </p>
    </div>
  );
};

const NotificationHandler = ({ onRefresh }) => {
  const { addToast } = useToasts();

  useEffect(() => {
    notificationHandler = (notif) => {
      addToast(<Notif title={notif.title} body={notif.body} />, {
        appearance: "info",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      onRefresh();
    };
  }, [addToast, onRefresh]);

  return null;
};

const SidebarWithScreens = (props) => {
  // const data = {};
  const { data = {}, run } = useRequest(
    () =>
      fetch(
        "https://app.appcraft.events/api/data/mPWnLdY5W1NR8I/common.json"
      ).then((res) => res.json()),
    {
      refreshOnWindowFocus: true,
      focusTimespan: 60 * 1000, // once per minute MAX
      pollingWhenHidden: false,
      pollingInterval: 10 * 60 * 1000, // 10 minutes
    }
  );
  const { app, notifications = [] } = data;
  return (
    <ToastProvider placement="top-center">
      <NotificationHandler
        autoDismiss
        autoDismissTimeout={3000}
        onRefresh={run}
      />
      <Sidebar
        {...props}
        screens={(app && app.screens) || getScreens()}
        notifications={notifications}
      />
    </ToastProvider>
  );
};

export default config.isMobile ? SidebarWithScreens : Sidebar;
