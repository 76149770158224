import { PureComponent } from "react";
import { h } from "preact";

import MapModule from "../core/map";
import { isMobileMode } from "../core/map/utils";

import config from "../config";
import Star from "./Star";

export class Img extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showPlaceholder: false
		};

		this.onError = () => {
			this.setState({
				showPlaceholder: true
			});
		};
	}

	render() {
		const { showPlaceholder } = this.state;
		const { defaultSrc, src, ...rest } = this.props;
		return <img {...rest} src={!src || showPlaceholder ? defaultSrc : src} onError={this.onError} />;
	}
}

export class ListExhibitor extends PureComponent {
	constructor(props) {
		super(props);

		if (!isMobileMode()) {
			this.onMouseEnter = () => {
				MapModule.highlightFeature({ target: this.props.event && this.props.event.polygon });
			};
			this.onMouseLeave = () => {
				MapModule.resetHighlight({ target: this.props.event && this.props.event.polygon });
			};
		}

		this.onClick = e => {
			console.log("click on", this.props.name);
			e.preventDefault();
			e.stopPropagation();

			if (config.exhibitorPopup) {
				MapModule.showExhibitor(this.props.exhibitor);
			} else {
				if (isMobileMode()) {
					MapModule.sidebar.close();
				}
				if (this.props.exhibitor.polygon) {
					MapModule.selectLayer(this.props.exhibitor.polygon, this.props.exhibitor);
				}
			}
		};

		this.onToggleFavorite = e => {
			e.preventDefault();
			e.stopPropagation();
			MapModule.toggleFavorite(this.props.id);
		};
	}

	render() {
		const { id, logo, favorite, name, picto, location, cite, activity } = this.props;

		return (
			<li
				class="exposant-entry"
				data-id={id}
				onClick={this.onClick}
				onMouseEnter={this.onMouseEnter}
				onMouseEnter={this.onMouseLeave}
			>
				<div class="exposant-left">
					<Img
						class="exposant-logo"
						src={`./assets/logos/${logo}`}
						defaultSrc="./assets/images/logo-placeholder.png"
						alt="logo"
					/>
				</div>
				<div class="exposant-right">
					{config.favorites && <Star active={favorite} onClick={this.onToggleFavorite} />}
					<h3 class="name" style={{ marginBottom: 6 }}>
						{name}
					</h3>
					<div class="stand-detail" style={{ marginBottom: 4 }}>
						{picto && <img class="picto" style={{ marginRight: 7 }} src={`./assets/images/pictos/${picto}`} />}
						<span class="stand-label">
							<span class="stand">{location}</span>
						</span>
					</div>
					<div style={{ fontSize: 12 }}>{activity}</div>
				</div>
				{/*<div class="c-button c-button--details">
          plus d’informations
        </div>*/}
			</li>
		);
	}
}
