import { h, Component } from 'preact';

import MapModule from '../core/map'
import { renderProgram } from './Program'
import { renderExhibitors } from './Exhibitors'

const FavoriteHeader = ({count, label}) => (
  <div class="list-header" style={{paddingLeft: 0}}>
    <div class="list-header__number">{count}</div>
    <div class="list-header__labels">
      <div class="list-header__title">{label}{count > 1 ? 's' : ''}</div>
      <div class="list-header__subtitle">favori{count > 1 ? 's' : ''}</div>
    </div>
  </div>
)

export class Favorites extends Component {


  render(){
    const { program, exhibitors } = this.props

    var exhibitorCnt = 0
    var eventCnt = 0
    const favorites = MapModule.favorites
    for(var key in favorites){
        if (favorites[key]) {
            if (key.indexOf("event") === 0) eventCnt++;
            else if (key.indexOf("exposant") === 0) exhibitorCnt++;
        }
    }
    
    return (
      <div class="sidebar-content-list" style="top: 50px;">
        {exhibitorCnt > 0 && <FavoriteHeader label="Exposant" count={exhibitorCnt} />}
        <div>
          <ul class="favorite-list">
            {renderExhibitors(exhibitors.filter(ex => favorites[ex.key]))}
          </ul>
        </div>
        {eventCnt > 0 && <FavoriteHeader label="Evénément" count={eventCnt} />}
        <div>
          <ul class="favorite-list">
            {renderProgram(program.filter(evt => favorites[evt.key]))}
          </ul>
        </div>
      </div>      
    )
  }
}