import { PureComponent } from 'react'
import { h } from 'preact';

// TODO : force unfocus ??

export default class Star extends PureComponent {

  handleClick = (e) => {
    this.props.onClick(e)
    this._div.blur() // Remove crappy hover effect on stars
  }
  
  render(){
    const { active, onClick, hasNotes } = this.props
    return (
      <div ref={el => this._div = el}
           class={`exposant-favorite ${active ? 'active' : ''}`}>
        <i onClick={this.handleClick} class="fa fa-star"></i>
        {hasNotes && <i class="fa fa-edit"></i>}
      </div>
    )
  }
}