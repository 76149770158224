import { h, Component } from "preact";
import MapModule from "../../../core/map";

function favoriteCount() {
	let cnt = 0;
	const favorites = MapModule.favorites || {};
	for (var key in favorites) {
		if (favorites[key]) {
			cnt++;
		}
	}
	return cnt;
}

function noteCount() {
	let cnt = 0;
	const notes = MapModule.notes || {};
	for (var key in notes) {
		if (notes[key]) {
			cnt++;
		}
	}
	return cnt;
}

export const bubbleCounts = {
	favorites: favoriteCount,
	notes: noteCount,
};

const BubbleCount = ({ type }) => {
	const fun = bubbleCounts[type];
	if (!fun) return null;

	return <div class="sidebar-tab__bubble">{fun()}</div>;
};

export default BubbleCount;
