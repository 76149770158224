import { h, Component } from "preact";

export class IFrameScreen {
	render() {
		const { url } = this.props;

		return (
			<div class="sidebar-content-list">
				<iframe
					scrolling
					style={{ width: "100%", height: "100%", border: 0 }}
					src={url}
				/>
			</div>
		);
	}
}
