import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import sortBy from 'lodash/sortBy'
// import merge from 'lodash/merge'

function group(data, key, positionKey){
  const list = data.filter(d => d[key])
  const groups = groupBy(list, key)
  console.log("groups", groups)
  if (!positionKey) return groups
  else {
    return mapValues(groups, groupList => sortBy(l => Number(l[positionKey])))
  }
}

const makePartialGroup = (group, key) => mapValues(group, v => ({[key]: v}))

function merge(grp1, grp2){
  const res = {...grp1}

  for(var key in grp2){
    if (!(key in res)){
      res[key] = grp2[key]
    } else {
      res[key] = {
        ...(res[key]), ...(grp2[key])
      }
    }
  }
  return res
}

export function makeParcours(exhibitors, program){
  console.log("exhibitors", exhibitors)

  // Group entries
  const exhibitorsParcours = group(exhibitors, "parcours")
  const programParcours = group(program, "parcours")//, "position")
  console.log("programParcours", programParcours)

  // Time to merge !
  return merge(
    makePartialGroup(exhibitorsParcours, "exhibitors"),
    makePartialGroup(programParcours, "program")
  )
}