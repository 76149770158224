import { Component, PureComponent } from 'react';
import { h } from 'preact';

import MapModule from '../core/map'

// import { renderExhibitor } from '../core/map/exhibitors'
// import { renderEvent } from '../core/map/program'

import { ListEvent, ListEventHeader } from './ListEvent'
import { ListExhibitor } from './ListExhibitor'
import { renderExhibitors } from './Exhibitors'
import { renderProgram } from './Program'

export default class List extends Component {

  render(){
    const { exhibitors, program } = this.props

    return (
      <ul class="list" ref={el => this.root = el}>
        {this.props.children}
        {renderProgram(program)}
        {this.renderExhibitors()}
      </ul>
    )
  }

  renderExhibitors(){
    const { exhibitors=[] } = this.props

    if (exhibitors.length == 0) return null

    return [
      <ListEventHeader title="Exposants" key="exp-title" />,
      renderExhibitors(exhibitors)
    ]
  }
}
