import { PureComponent } from 'react'
import { h } from 'preact';

import MapModule from '../core/map'
import { isMobileMode } from '../core/map/utils'

import config from '../config'
import Star from './Star'

export class ListEventHeader extends PureComponent {
  render(){
    const { id, title } = this.props
    return (
      <li class={`exposant-entry event event--header event--header--${id}`} data-always-visible="true">
        <div class="event--header__date">{title}</div>
        <span class="name always-visible" style="display: none"></span>
      </li>
    )
  }
}


function displayTime(time){
  if (!time) return time
  return time.replace('h', ':')
}

export class ListEvent extends PureComponent {

  constructor(props){
    super(props)

    if (!isMobileMode()){
      this.onMouseEnter = () => {
        MapModule.highlightFeature({ target: this.props.event.polygon })
      }
      this.onMouseLeave = () => {
        MapModule.resetHighlight({ target: this.props.event.polygon })
      }
    }

    this.onClick = (e) => {
      e.preventDefault()
      e.stopPropagation()

      if (config.eventPopup){
          MapModule.showEvent(this.props.event)
      } else {
        if (isMobileMode()){
            MapModule.sidebar.close()
        }
        
        console.log("polygon", this.props.event.polygon)
        
        if (this.props.event.polygon){
            MapModule.selectLayer(this.props.event.polygon, null, this.props.event)
        } else { // Nothing to show, restore zoom ?
            MapModule.selectLayer(null)
        }
      }
    }

    this.onToggleFavorite = (e) => {
      e.stopPropagation()
      e.preventDefault()
      console.log("toggle", this.props.event.key)
      MapModule.toggleFavorite(this.props.event.key)
    }
  }

  render(){
    const { id, color, icon, favorite, title, partner, startTime, endTime, location, locationId, picto, dateKey, note } = this.props

    return (
      <li class={`exposant-entry event event--${dateKey}`} 
          data-id={id} 
          onClick={this.onClick} 
          onMouseEnter={this.onMouseEnter}
          onMouseEnter={this.onMouseLeave}>
        <div class="exposant-right">
          {config.favorites && <Star active={favorite} 
                                   onClick={this.onToggleFavorite} 
                                   hasNotes={note} />}
		  <div class="time"><span style="margin-left:5px;margin-right:10px;"><i class="fa fa-clock-o"></i></span> <span class="time time--start" style={{backgroundColor: color}}>{displayTime(startTime)}</span> - {displayTime(endTime)}</div>
		  {location && <div class="date theme" style="display:block;padding:4px;margin-top:6px;"><span style="margin-left:5px;margin-right:12px;"><i class="fa fa-location-arrow"></i></span> {location}</div>}
          <h3 style="margin-left:37px;margin-top:8px;">
            <span class="name">{title}</span>
          </h3>
          {partner && <div class={"event__partner " + "event__partner--" + locationId} style="margin-left:38px;">{partner}</div>}
        </div>
        {config.eventPopup && <div class="c-button c-button--details">
          plus d’informations
        </div>}
      </li>
    )
  }
}

