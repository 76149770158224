// import programCSV from './export-conference.csv'
import programCSV from "raw-loader!./program.csv";

import moment from "moment";
import "moment/locale/fr";
import values from "lodash/values";
import orderBy from "lodash/orderBy";
import fr from "moment/dist/locale/fr";
moment.locale("fr", fr);

// jour	startTime	endTime	salle	icon	type	info	title	subtitle	speakers	moderator	animator	partner	theme	parcours	position
// Code	Dates des sessions	Lieux des sessions	Description	Résumé
import Papa from "papaparse";
const rawProgram = Papa.parse(programCSV, {
  header: true,
  skipEmptyLines: true,
}).data;

const program = [];
const daysByKey = {};

// console.log("program", rawProgram)

// console.log(rawProgram)

const ids = {};
const parcours = {};

for (let conf of rawProgram) {
  // console.log("conf", conf)
  const date = conf.start;

  // 28/09/17 15h00-15h30
  const jour = date.substring(0, 10);
  const m = moment.parseZone(jour, "YYYY-MM-DD").locale("fr");

  const startTime = conf.start.substring(11, 16).replace(":", "h");
  const endTime = conf.end.substring(11, 16).replace(":", "h");
  conf.id = conf._id;

  const subtitle = conf["Description"] || "";
  const dateLabel = m.format("dddd, D MMMM");

  const dateKey = m.format("YY_MM_DD");
  if (!daysByKey[dateKey]) {
    daysByKey[dateKey] = {
      key: dateKey,
      day: m.format("dddd"),
      date: m.format("D MMM"),
      fullDate: dateLabel,
    };
  }
  if (ids[conf.id]) {
    console.error("Duplicate id", conf.id);
  }
  ids[conf.id] = true;

  program.push({
    ...conf,
    key: "event-" + conf.id,
    dateKey,
    dateLabel,
    jour,
    startTime,
    endTime,
    subtitle,
  });
  // parcours[conf.Parcours] = true
  // salle => location
}
// console.log(Object.keys(parcours))

const days = orderBy(values(daysByKey), "key");

export { rawProgram, program, days };
