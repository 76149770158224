import { h, Component } from 'preact';

import MapModule from '../core/map'
import config from '../config'
import { renderProgram } from './Program'

import values from 'lodash/values'

export class Notes extends Component {
  
  state = {
    email: ""
  }

  handleChange = (evt) => {
    console.log("onChange")
    this.setState({email: evt.target.value})
  }

  send = (evt) => {
    evt.preventDefault()
    evt.stopPropagation()

    console.log("send to ", this.state.email, this.props.notes)

    fetch("https://app.appcraft.events/mail/send", {
      method: "POST",
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({to: this.state.email, notes: this.props.notes, app: config.id})
    })
    .then(res => {
      console.log("finished, open home")
      MapModule.sidebar.open("home")
    }) 
  }
  
  render(){
    const { email } = this.state
    const { program, notes } = this.props
    const cleanNotes = values(notes || {}).filter(n => n) // Keep real notes

    console.log(email)

    return (
      <div>
        <div>
          {cleanNotes.length == 0 && "Aucune note "}
          {cleanNotes.length == 1 && "1 note "}
          {cleanNotes.length > 1 && (cleanNotes.length + " notes ")}
          à télécharger
        </div>
        <div style={{paddingTop: 8}}>
          <input style={{fontSize: 16, padding: 8, width: '100%'}}
                 value={email}
                 onInput={this.handleChange}
                 type="email"
                 placeholder="tapez votre email"/>
          <button class="sidebar__button--send"
                  onClick={this.send}
                  disabled={email.length == 0}>
            <i class="fa fa-mail-forward" /> Envoyer par email
          </button>
        </div>
        <div  className="sidebar-content-list" style={{top: 200}}>
          <ul class="favorite-list">
            {renderProgram(program.filter(evt => notes[evt.key]))}
          </ul>
        </div>
      </div>
    )
  }
}