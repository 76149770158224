import { h, Component } from 'preact';

import MapModule from '../core/map'
import config from '../config'
import Star from './Star'

import { isMobileMode } from '../core/map/utils'

const ItemSection = ({title, details, html}) => (
  <div>
    <h2 class="c-info__header">{title}</h2>
    {details && <p>{details}</p>}
    {html && <p dangerouslySetInnerHTML={{__html: html}} />}
  </div>
)

function noop(e){
  if (e) {
    e.preventDefault()
    e.stopPropagation()
  }
}

export default class EventPopup extends Component {
  
  state = {
    showNotes: false
  }

  handleLocationClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (isMobileMode()){
        MapModule.sidebar.close()
    }
      
    MapModule.selectLayer(this.props.event.polygon, null, this.props.event)
    this.props.onClose(e)
  }
  
  handleNoteClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({
      showNotes: true,
      notes: MapModule.notes[this.props.event.key] || ""
    })
  }

  handleNoteChange = (e) => this.setState({notes: e.target.value})

  handleSave = () => {
    MapModule.saveNote(this.props.event.key, this.state.notes)
    this.setState({ showNotes: false })
  }

  toggleFavorite = (e) => {
    e.stopPropagation()
    e.preventDefault()
    console.log("toggle", this.props.event.key)
    MapModule.toggleFavorite(this.props.event.key)
    // Force refresh for this component
    setTimeout(() => this.forceUpdate(), 50) 
  }

  openPartner = (e, partner) => {
    e.preventDefault()
    e.stopPropagation()
    let exhibitor = MapModule.allStands.find(ex => ex.name === partner || ex.name.toLowerCase() === partner.toLowerCase())
    console.log("MapModule.allStands", MapModule.allStands)
    console.log("openPartner", partner, exhibitor)
    if (!exhibitor && config.exhibitorNames) {
      const name = config.exhibitorNames[partner]
      if (name) exhibitor = MapModule.allStands.find(ex => ex.name === name)
    }
    if (exhibitor){
      MapModule.showExhibitor(exhibitor)
    }
  }

  render(){
    const { event, onClose } = this.props
    
    const { showNotes, notes } = this.state
    
    if (showNotes){
      const { title } = event
      return (
        <div class="c-popup" onClick={e => e.stopPropagation()}>
          <i class="c-popup__close fa fa-save" onClick={this.handleSave}></i>
          <div class="c-popup__title">Notes</div>
          <div class="c-popup__body" style={{display: 'flex', flexDirection: 'column'}}>
            <h3>
              <span class="name">{title}</span>
            </h3>
            <div style={{flex: 1, position: 'relative'}}>
              <textarea value={notes} 
                        autofocus
                        onInput={this.handleNoteChange} 
                        style={{position: 'absolute', width: '100%', height: '100%', fontSize: 14}} />
            </div>
          </div>
        </div>
      )
    }

    const { dateKey, title, subtitle, icon, picto, location, locationId, startTime, endTime,
      speakers, partner, theme, moderator, animator, description, partners=[] } = event
    const favorite = MapModule.favorites[event.key]


    const partnerEls = partners.map((partner, idx) => [
      (idx != 0 && <span key={idx}>, </span>),
      <a key={partner} href="#" onClick={(e) => this.openPartner(e, partner)}>{partner}</a>
    ])

    const eventNotes = MapModule.notes[event.key]

    return (
      <div class={`c-popup c-popup--event--${dateKey}`} onClick={e => e.stopPropagation()}>
        <i class="c-popup__close fa fa-times" onClick={onClose}></i>
        <div class="c-popup__title">Détail événement</div>
        <div class="c-popup__body">
          <div class={`event event--${dateKey} clearfix`}>
            <div class="exposant-left" style={{textAlign: 'center'}}>
              <div class="time time--start" style="background-color: {{color}};">{startTime}</div>
              <div class="time">{endTime}</div>
                {icon && <img class="picto" src={"./assets/images/pictos/"+icon.replace(".png", ".svg")} />}
                {icon && <span>&nbsp;</span>}
            </div>
            <div class="exposant-right">
              {config.favorites && <Star active={favorite} onClick={this.toggleFavorite} />}
              <h3>
                <span class="name">{title}</span>
              </h3>
              <div class="event-detail">
                {picto && <img class="picto" style={{marginRight: 4}} src={"./assets/images/pictos/" + picto.replace(".png", ".svg")} />}
                <div class="date theme">{location}</div>
              </div>
            </div>
          </div>
          
          <div style="text-align:center">
            <button class="c-button c-button--vote" 
                onClick={this.handleLocationClick}
                style={{display: 'inline', margin: 4}}>
              <i class="fa fa-map-marker" /> Voir sur le plan
            </button>
            {config.hasNotes && <button class="c-button c-button--vote" 
                onClick={this.handleNoteClick}
                style={{display: 'inline', margin: 4}}>
              <i class="fa fa-pencil-square-o" /> {eventNotes ? "Editer vos notes" : "Prendre des notes"}
            </button>}
          </div>
          
          {subtitle && <p>{subtitle}</p>}
          {/*<p>Parcours : 
              {{#picto}}
                <img class="picto" src="./assets/images/pictos/{{picto}}" />
              {{/picto}}
          {{parcours}}</p>*/}
          {description && <ItemSection title="Description" html={description} />}
          {speakers && <ItemSection title="Intervenants" details={speakers} />}
          {moderator && <ItemSection title="Modérateur" details={moderator} />}
          {animator && <ItemSection title="Animateur" details={animator} />}
          {partners.length > 1 && <ItemSection title="Partenaires" details={partnerEls} />}
          {partners.length == 1 && <ItemSection title="Partenaire" details={partnerEls} />}
          {theme && <ItemSection title="Thème" details={theme} />}
        </div>
      </div>
    )
  }

}
