import moment from "moment";
import fr from "moment/dist/locale/fr";
import { h } from "preact";
import { useEffect } from "preact/hooks";
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from "reactstrap";
import create from "zustand";
import { persist } from "zustand/middleware";
moment.locale("fr", fr);

export const useNotificationsStore = create(
  persist(
    (set, get) => ({
      readNotifications: {},
      markAsRead: (notificationId) => {
        set(({ readNotifications }) => ({
          readNotifications: {
            ...readNotifications,
            [notificationId]: true,
          },
        }));
      },
      markAllAsRead: (notifications) => {
        set(({ readNotifications }) => ({
          readNotifications: notifications.reduce(
            (obj, notif) => {
              obj[notif._id] = true;
              return obj;
            },
            { ...readNotifications }
          ),
        }));
      },
    }),
    {
      name: "notifications", // unique name
    }
  )
);

export const NotificationsBubbleCount = ({ notifications }) => {
  const { readNotifications } = useNotificationsStore();
  const unreadCount = notifications.filter(
    (notif) => !readNotifications[notif._id]
  );
  if (!unreadCount.length) return null;
  return <div class="sidebar-tab__bubble">{unreadCount.length}</div>;
};

const NotificationsScreen = ({ notifications }) => {
  const { readNotifications, markAllAsRead } = useNotificationsStore();

  useEffect(() => {
    return () => {
      markAllAsRead(notifications);
    };
  }, []);
  window.moment = moment;
  return (
    <div class="sidebar-content-list">
      <div class="tw-bs" style={{ padding: 16 }}>
        <p>{notifications.length} notifications</p>
        <ListGroup>
          {notifications.map((notif) => {
            return (
              <ListGroupItem key={notif._id}>
                <ListGroupItemHeading tag="h4">
                  {notif.title}
                </ListGroupItemHeading>
                <ListGroupItemText style={{ fontStyle: "italic" }}>
                  {moment(notif.date).locale("fr").format("LLLL")}
                </ListGroupItemText>
                {notif.bodyHtml && (
                  <ListGroupItemText
                    className="html-content"
                    dangerouslySetInnerHTML={{ __html: notif.bodyHtml }}
                  />
                )}
                {!notif.bodyHtml && (
                  <ListGroupItemText style={{ whiteSpace: "pre-line" }}>
                    {notif.body}
                  </ListGroupItemText>
                )}
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </div>
    </div>
  );
};
export default NotificationsScreen;
