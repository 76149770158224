
import config from '../config'


function isIOS(){
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export function trackScreen(screenName){
  console.log(`trackScreen(${screenName})`)
  if (!config.isMobile) return

  if (window.NativeCode){
    window.NativeCode.trackScreen(screenName)
  } else if (isIOS()) {
    // console.log("call", `dpmap-stats://screen/${screenName}`)
    // window.location = `dpmap-stats://screen/${screenName}`
  }
}


export function trackEvent(eventType, eventValue){
  console.log(`trackEvent(${eventType}, ${eventValue})`)
  if (!config.isMobile) return

  if (window.NativeCode){
    window.NativeCode.trackEvent(eventType, eventValue)
  } else if (isIOS()) {
    // const url = `dpmap-stats://event/${eventType}?name=${encodeURIComponent(eventValue)}`
    // console.log("call", url)
    // window.location = url
  }
}

