import { h } from "preact";
import banner from "../../../assets/images/banner.png";
import MapModule from "../../../core/map";
import { NotificationsBubbleCount } from "../../NotificationsScreen";
import BubbleCount from "./BubbleCount";
import "./HomeGrid.less";

const HomeIcon = ({ icon, svgIcon }) => {
  if (icon) {
    return (
      <div className="icon">
        <i class={"fa fa-" + icon} />
      </div>
    );
  }
  if (svgIcon) {
    return (
      <div className="svg-icon" dangerouslySetInnerHTML={{ __html: svgIcon }} />
    );
  }
  return null;
};

function showMap(e) {
  e.preventDefault();
  e.stopPropagation();
  MapModule.sidebar.close();
}

const HomeGrid = ({ screens, notifications }) => {
  return (
    <div className="HomeGrid" data-favorite="true">
      <img className="banner" src={banner} />
      <div className="columns">
        {screens.map((screen) => {
          const isUrl = screen.type === "url";
          return (
            <a
              class={
                "column col-4 " +
                (screen.name === "map" ? "" : "home-menu-link")
              }
              href={isUrl ? screen.url : "#" + screen.name}
              target={isUrl ? "_blank" : undefined}
              role={isUrl ? undefined : "tab"}
              onClick={screen.name === "map" ? showMap : undefined}
            >
              {screen.type === "notifications" && (
                <NotificationsBubbleCount
                  type={screen.name}
                  notifications={notifications}
                />
              )}
              {screen.type !== "notifications" && (
                <BubbleCount type={screen.name} />
              )}
              <HomeIcon {...screen} />
              <div className="label">{screen.label}</div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default HomeGrid;
