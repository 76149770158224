import { useSetState } from "ahooks";
import config from "../config";
import get from "lodash/get";
import { h } from "preact";
import { Form, FormGroup, Input, Label, Button } from "reactstrap";
import { useUserStore } from "./store";

const requiresName = get(config, ["vote", "requireName"]);

const Field = ({ label, type, name, value, onChange }) => (
  <FormGroup>
    <Label for={name}>{label}</Label>
    <Input
      id={name}
      type={type}
      name={name}
      style={{
        display: "block",
        width: "100%",
        fontSize: 14,
        marginBottom: 8,
        padding: 4,
      }}
      onInput={(e) => onChange({ [name]: e.target.value })}
      value={value}
    />
  </FormGroup>
);

const ConfigureUser = ({ setUser: save }) => {
  const [user, setUser] = useSetState({});

  const isValid = user.firstName && user.lastName && user.email && user.company;

  function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (isValid) {
      save(user);
    }
  }

  return (
    <div class="sidebar-content-list tw-bs">
      <div style={{ padding: 12, position: "relative" }}>
        <p>
          Merci de rentrer vos informations afin de pouvoir accéder à cette
          partie de l'application
        </p>
        <Form onSubmit={handleSubmit}>
          <Field
            name="firstName"
            label="Prénom"
            value={user.firstName}
            onChange={setUser}
          />
          <Field
            name="lastName"
            label="Nom"
            value={user.lastName}
            onChange={setUser}
          />
          <Field
            name="email"
            label="Email"
            value={user.email}
            onChange={setUser}
            type="email"
          />
          <Field
            name="company"
            label="Cabinet"
            value={user.company}
            onChange={setUser}
          />
          <Button
            type="submit"
            color="primary"
            onClick={handleSubmit}
            disabled={!isValid}
          >
            Enregistrer
          </Button>
        </Form>
      </div>
    </div>
  );
};

export function requireName(Component) {
  if (!requiresName) return Component;

  return ({ requiresUser, ...props }) => {
    // If no name... ask for it !
    const { user, setUser } = useUserStore();
    console.log("requiresUser", requiresUser);
    if (requiresUser && !user) {
      return <ConfigureUser setUser={setUser} />;
    }
    return <Component {...props} user={user} />;
  };
}
