import { h, Component } from 'preact';

import MapModule from '../core/map'
import { ListEventHeader, ListEvent } from './ListEvent'



export function renderProgram(program=[], dateKey){
  const eventItems = []
  const visibleProgram = (!dateKey || dateKey === 'all') ? program : program.filter(evt => (dateKey === evt.dateKey))

  let date = ''
  for(var event of visibleProgram){
    // console.log("event", event)
    if (date != event.dateLabel){
        date = event.dateLabel
        eventItems.push(<ListEventHeader key={date} title={date} id={event.dateKey} />);
    }
    eventItems.push(<ListEvent {...event} 
                                key={event.id} 
                                event={event}
                                favorite={MapModule.favorites[event.key]}
                                note={MapModule.notes[event.key]} />)
    // renderEvent(MapModule, event, event.key, this.root)
  }
  return eventItems
}

const Tab = ({id, children, active, onSelect}) => (
  <div class={`event-tab event-tab--${id} ${active ? 'active' : ''}`} onClick={() => onSelect(id)}>{children}</div>
)

export class Program extends Component {

  constructor(props){
    super(props)

    this.state = {
      tab: 'all'
    }

    this.setActiveTab = (tab) => { 
      this.list.scrollTop = 0
      this.setState({tab})
    }
  }

  render(){
    const {  program, days } = this.props
    const { tab } = this.state

    return (
      <div class="sidebar-content-list">
        <div class="event-tabs">
          <Tab id="all" active={tab === "all"} onSelect={this.setActiveTab}>Tous</Tab>
          {days.map(({key, day, date}) => (
            <Tab key={key} id={key} active={tab === key} onSelect={this.setActiveTab}>{day}<br />{date}</Tab>
          ))}
        </div>
        <div class="event-tab-content active">
          <ul class="list" ref={(c) => this.list = c}>
            {renderProgram(program, tab)}
          </ul>
        </div>
      </div>
    )
  }
}