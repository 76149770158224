import { h, Component } from 'preact';

import MapModule from '../core/map'
import { ListExhibitor } from './ListExhibitor'

export function renderExhibitors(exhibitors) {
  return exhibitors.map(ex => <ListExhibitor {...ex} key={ex.key} id={ex.key} favorite={MapModule.favorites[ex.key]} exhibitor={ex} />)
}

export class Exhibitors extends Component {

  render(){
    const {  exhibitors } = this.props

    return (
      <div class="sidebar-content-list">
        <ul class="list">
          {renderExhibitors(exhibitors)}
        </ul>
      </div>
    )
  }
}