import create from "zustand";
import { persist } from "zustand/middleware";
import md5 from "fast-md5";

export const useUserStore = create(
	persist(
		(set, get) => ({
			user: undefined,
			setUser: (user) =>
				set({
					user: { userId: md5(`${user.firstName} ${user.lastName} ${user.email} ${user.company}`), ...user },
				}),
		}),
		{
			name: "user", // unique name
		}
	)
);
