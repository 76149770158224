import stringify from "querystring/encode";
import { h } from "preact";
import { requireName } from "../core/require-name";
import { IFrameScreen } from "./IFrameScreen";
import get from "lodash/get";

function ensureString(v) {
	if (typeof v === "number") return v.toString();
	if (Array.isArray(v)) return v.map(ensureString).join(", ");
	return v || "";
}

export function replaceUriValues(text, item) {
	if (!text) return "";
	return text
		.replace(/{(.*?)}/g, (m, key) => encodeURIComponent(ensureString(get(item, key))))
		.replace(/ /, "%20");
}

export function injectParams(url, params) {
	if (!url) return "";
	if (url.indexOf("?") !== -1) {
		return `${url}&${stringify(params)}`;
	}
	return `${url}?${stringify(params)}`;
}

export const QuizScreen = requireName(({ name, user, injectUser, url: defaultUrl, iframeProps }) => {
	let url = replaceUriValues(defaultUrl || "https://quiz.appcraft.events/client/", user);
	if (injectUser && user) {
		url = injectParams(url, user);
	}

	return <IFrameScreen url={url} {...iframeProps} />;
});
