import { h, Component } from 'preact';

import MapModule from '../core/map'

import startsWith from 'lodash/startsWith'
import isEqual from 'lodash/isEqual'

import List from './list'
import { trackEvent } from '../core/analytics';
import debounce from 'lodash/debounce'

const trackSearch = debounce(trackEvent, 1000, {leading: false, trailing: true})

function autoShowKeyboard(evt){
  if (window.showKeyboard){
    window.showKeyboard(evt.target)
  }
}

function matches(query, value){
  if (!query) return true
  if (!value) return false
  return value.toLowerCase().indexOf(query) >= 0
}

    // const resultCount = program.length + exhibitors.length
    // console.log("resultCount", resultCount)
    // let countString = ""
    // if (resultCount == 0) countString = "Aucun résultat"
    // else if (resultCount == 1) countString = "Un résultat"
    // else {
    //   countString = resultCount + " résultats ("
    //   if (program.length == 1) countString += `1 événement`
    //   else if (program.length) countString += `${program.length} événements`
    //   if (program.length && exhibitors.length) countString += ", "
    //   if (exhibitors.length == 1) countString += `1 exposant`
    //   else if (exhibitors.length) countString += `${exhibitors.length} exposants`
    //   countString += ")"
    // }

const SearchCount = ({eventCount, exhibitorCount}) => (
  <div className="c-dashboard">
    <div className="c-dashboard__block">
      <div className="c-dashboard__count">{eventCount}</div>
      <div className="c-dashboard__label">{eventCount > 1 ? "événements" : "événement"}</div>
    </div>
    <div className="c-dashboard__block">
      <div className="c-dashboard__count">{exhibitorCount}</div>
      <div className="c-dashboard__label">{exhibitorCount > 1 ? "exposants" : "exposant"}</div>
    </div>
  </div>
)

export default class SearchPane extends Component {

  constructor(props){
    super(props)
    this._mounted = false
    const { program, exhibitors } = props
    this.state = {
      version: 0,
      query: "",
      program, exhibitors
    }
  }

  onChange = (e) => {
    const { program, exhibitors } = this.props

    // console.error("break here")

    const query = e.target.value

    
    const lowerQuery = query.toLowerCase()
    if (lowerQuery) trackSearch("search", lowerQuery)

    // console.log("lowerQuery", lowerQuery)
    const nextProgram = program.filter(evt => (
      matches(lowerQuery, evt.location)
      || matches(lowerQuery, evt.title)
      || matches(lowerQuery, evt.subtitle)
      || matches(lowerQuery, evt.speakers)
      || matches(lowerQuery, evt.partner)
    ))
    const nextExhibitors = exhibitors.filter(p => (
      matches(lowerQuery, p.name) 
      || matches(lowerQuery, p.location)
      || matches(lowerQuery, p.activity)
    ))
    if (isEqual(this.state.program, nextProgram) && isEqual(this.state.exhibitors, nextExhibitors)){
      // Nothing changed !
      this.setState({ query })
    } else {
      this.setState({
        query, 
        version: this.state.version+1,
        program: nextProgram,
        exhibitors: nextExhibitors,
      })
    }
  }

  render(){
    const { program, exhibitors, version, query } = this.state
              //  onBlur={() => { if (window.hideKeyboard) window.hideKeyboard()}}

    return (
      <div id="search-list" class="sidebar-content-list">
        <input tabIndex={-1} class="search" type="search" placeholder="Recherche" value={query} 
               onFocus={autoShowKeyboard}
               onClick={autoShowKeyboard}
               onInput={this.onChange} />
        <List key={version} program={program} exhibitors={exhibitors} autoHide={true}>
          <li class="list-entry list-entry--count" data-id="{{id}}" style={{position: 'relative'}}>
            <SearchCount eventCount={program.length} exhibitorCount={exhibitors.length} />
          </li>
        </List>
      </div>
    )
  }
}