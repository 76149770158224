import { h, Component } from 'preact';

import MapModule from '../core/map'
import config from '../config'
import Star from './Star'

import { isMobileMode } from '../core/map/utils'
import { Img } from './ListExhibitor'

const ItemSection = ({title, details, html}) => (
  <div>
    <h2 class="c-info__header">{title}</h2>
    {details && <p>{details}</p>}
    {html && <div dangerouslySetInnerHTML={{__html: html}} />}
  </div>
)

export default class ExhibitorPopup extends Component {

  constructor(props){
    super(props)

    this.handleLocationClick = (e) => {
      e.preventDefault()
      e.stopPropagation()

      if (isMobileMode()){
          MapModule.sidebar.close()
      }
        
      MapModule.selectLayer(this.props.exhibitor.polygon, this.props.exhibitor)
      this.props.onClose(e)
    }
    
    this.toggleFavorite = (e) => {
      e.stopPropagation()
      e.preventDefault()
      console.log("toggle", this.props.exhibitor.key)
      MapModule.toggleFavorite(this.props.exhibitor.key)
      // Force refresh for this component
      setTimeout(() => this.forceUpdate(), 50) 
    }
  }

  render(){
    const { exhibitor, onClose } = this.props
    const { name, logo, picto, location, promo, description, activity, nouveau } = exhibitor
    const favorite = MapModule.favorites[exhibitor.key]

    return (
      <div class="c-popup" onClick={e => e.stopPropagation()}>
        <i class="c-popup__close fa fa-times" onClick={onClose}></i>
        <div class="c-popup__title">Détail exposant</div>
        <div class="c-popup__body">
          <div class="event clearfix">
            <div class="exposant-left" style="text-align: center;">
              <Img 
                class="exposant-logo" 
                src={`./assets/logos/${logo}`} 
                defaultSrc="./assets/images/logo-placeholder.png" 
                alt="logo" />
            </div>
            <div class="exposant-right">
              {config.favorites && <Star active={favorite} onClick={this.toggleFavorite} />}
              {name}<br />
              <div>
                {picto && <img class="picto" style={{marginRight: 2}} src={`./assets/images/pictos/${picto}`} />}
                <span class="stand-label"><span class="stand">{location}</span></span>
              </div>
            </div>
          </div>
          {nouveau && <ItemSection title="Nouveau partenaire" />}
          {<h3>{activity}</h3>}
          {/*{metier && <ItemSection title="Métier" details={metier} />}*/}
          
          <p><a id="exhibitor-map-link" href="#" onClick={this.handleLocationClick}>
            <i class="fa fa-map-marker" /> Voir sur le plan
          </a></p>

          {/*{metier && <ItemSection title="Métier" details={metier} />}*/}
          {promo && <ItemSection title="Offre Promotionnelle" details={promo} />}
          {description && <ItemSection title="Description" html={description} />}
        </div>
      </div>
    )
  }
}
