import { h } from "preact";
import MapModule from "../core/map";
import { MultiIframeScreen } from "./MultiIframeScreen";
import { QuizScreen } from "./QuizScreen";

const Screen = ({ screen }) => {
  const { type, name, requiresUser } = screen;
  switch (type) {
    case "quiz": {
      return (
        <QuizScreen
          name={name}
          url={screen.url}
          requiresUser={requiresUser}
          injectUser={screen.injectUser}
          iframeProps={screen.iframeProps}
        />
      );
    }
    case "multi-iframe": {
      return (
        <MultiIframeScreen
          tabs={screen.tabs}
          requiresUser={requiresUser}
          injectUser={screen.injectUser}
          iframeProps={screen.iframeProps}
        />
      );
    }
    case "screen-list": {
      return <ScreenListScreen screens={screen.screens} />;
    }
    case "url": {
      return null;
    }
  }
};

const ScreenPopup = ({ title, onClose, children }) => (
  <div class="c-popup c-popup--fullscreen" onClick={(e) => e.stopPropagation()}>
    <i class="c-popup__close fa fa-times" onClick={onClose} />
    <div class="c-popup__title">{title}</div>
    <div class="c-popup__body" style={{ padding: 0 }}>
      {children}
    </div>
  </div>
);

const ScreenListScreen = ({ screens }) => {
  function handleClick(e, screen) {
    e.preventDefault();
    e.stopPropagation();
    MapModule.renderPopup(
      <ScreenPopup title={screen.label || screen.title}>
        <Screen screen={screen} />
      </ScreenPopup>
    );
  }
  return (
    <div class="sidebar-content-list">
      <div class="c-button-list">
        {screens.map((screen, idx) => {
          return (
            <button
              key={idx}
              className="c-button"
              onClick={(e) => handleClick(e, screen)}
            >
              {screen.label || screen.title}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ScreenListScreen;
