import { h, Component } from 'preact';

import MapModule from '../core/map'
import { renderProgram } from './Program'
import { renderExhibitors } from './Exhibitors'

import config from '../config'

function closeBox(e){
  e.stopPropagation()

  MapModule.unselectLayer()
  MapModule.info.update()
}

const BoxHeader = ({title, subtitle, picto}) => (
  <div class="info__title">
    {picto && <img class="info__picto" src={`./assets/images/pictos/${picto.replace(".png", ".svg")}`} />}
    <i id="info-close" class="fa fa-times info__close" onClick={closeBox}></i>
    <div class="title">{title}</div>
    <div class="subtitle">{subtitle}</div>
  </div>
)

function headerLocationFor(item){
  const id = item.locationId
  console.log("header for", id)
  if (id in config.locationNames){
    return config.locationNames[id]
  }
  return item.location
}

const POI = ({location, locationId, name, id, picto}) => (
  <li class={`exposant-entry poi poi--${id}`}
      data-id={id}>
    <div class="exposant-right">
      <div class="stand-detail" style={{marginBottom: 4}}>
        {picto && <img class="picto" style={{marginRight: 7}} src={`./assets/images/pictos/${picto.replace(".png", ".svg")}`} />}
        <span class="stand-label"><span class="stand">{name}</span></span>
      </div>
    </div>
  </li>
)

export default class InfoBox extends Component {

  componentDidMount(){
    // Refresh for favorites
    MapModule.refreshInfo = () => this.forceUpdate()
  }

  render(){
    let { exhibitors=[], program=[], pois=[], firstExhibitor, firstEvent } = this.props



    console.log('render', exhibitors, program)
    console.log("firstExhibitor", firstExhibitor)
    console.log("firstEvent", firstEvent)
    console.log("pois", pois)
    if (exhibitors.length == 0 && program.length == 0 && pois.length == 0) {
      return <div style={{display: 'none'}} />
    }

    if (pois.length > 0){
      return (
        <div>
          <BoxHeader title={pois[0].name}
                     picto={pois[0].picto}
                     subtitle="Point d'intérêt" />
          {/* <ul>
            <POI {...pois[0]} />
          </ul> */}
        </div>
      )
    }

    if (program.length > 0){
      if (firstEvent){ // Reorder list
        program = [firstEvent, ...program.filter(e => e.key !== firstEvent.key)]
      }
      return (
        <div>
          <BoxHeader title={headerLocationFor(program[0])} 
                     picto={program[0].picto}
                     subtitle="Programme" />
          <ul>
            {renderProgram(program)}
          </ul>
        </div>
      )
    }

    const cnt = exhibitors.length
    if (firstExhibitor){ // Reorder list
      exhibitors = [firstExhibitor, ...exhibitors.filter(e => e.key !== firstExhibitor.key)]
    }
    return (
      <div>
          <BoxHeader title={headerLocationFor(exhibitors[0])} 
                     picto={exhibitors[0].picto}
                     subtitle={cnt + " " + (cnt > 1 ? "Exposants" : "Exposant")} />
          <ul>
            {renderExhibitors(exhibitors)}
          </ul>
      </div>
    )
  }
}

      // str += '<div class="shadow-top"></div>'
      // str += '<div class="shadow-bottom"></div>'
      // this._div.innerHTML = str

      // $(this._div).find(".exposant-entry").each(function (idx, el) {
      //   var key = $(el).data("id")
      //   $(el).find(".exposant-favorite").click(function (evt) {
      //     evt.stopPropagation()
      //     self.toggleFavorite(key)
      //   })
      //   $(el).find(".c-button--details").click(function (evt) {
      //     evt.stopPropagation()
      //     const exhibitor = self.allStands.find(s => s.key === key)
      //     if (exhibitor){
      //       self.showExhibitor(exhibitor)
      //     }
      //     const event = self.allEvents.find(e => e.key === key)
      //     if (event){
      //       self.showEvent(event)
      //     }
      //   })
      // })

    //   var ul = $(this._div).find("ul")
    //   var shadowBottom = $(this._div).find(".shadow-bottom")
    //   var shadowTop = $(this._div).find(".shadow-top")
    //   function updateShadow() {
    //     var height = ul.outerHeight();
    //     if (ul.scrollTop() > 0) {
    //       shadowTop.fadeIn(125);
    //     } else {
    //       shadowTop.fadeOut(125);
    //     }
    //     if (ul.scrollTop() + height >= ul[0].scrollHeight) {
    //       shadowBottom.fadeOut(125);
    //     } else {
    //       shadowBottom.fadeIn(125);
    //     }
    //   }

    //   ul.on('scroll', function () {
    //     updateShadow()
    //   })
    //   updateShadow()
    // } else {
    //   this._div.style.display = 'none'
    //   this._div.innerHTML = '<h4>Informations</h4>Sélectionnez un stand'
    // }