import config from "../../config";
import { h, render } from "preact";
import React from "react";

import EventPopup from "../../components/EventPopup";
import ExhibitorPopup from "../../components/ExhibitorPopup";
import InfoBox from "../../components/InfoBox";
import { trackEvent, trackScreen } from "../analytics";
import initSegments from "./segments";

const favoritesKey = `favorites-${config.id}-2017`;
const notesKey = `notes-${config.id}-2017`;

const self = {};
if (config.favorites) {
  self.favorites = config.favorites
    ? JSON.parse(localStorage.getItem(favoritesKey) || "{}")
    : {};
} else {
  self.favorites = {};
}

if (config.hasNotes) {
  self.notes = JSON.parse(localStorage.getItem(notesKey) || "{}");
} else {
  self.notes = {};
}

function makeBulk(array) {
  const a = [];
  for (let i = 0; i < array.length; i++) {
    if (i % 2 === 0) {
      a.push([array[i]]);
    } else {
      a[Math.floor(i / 2)].push(array[i]);
    }
  }
  return a;
}

function parseTransform(transform) {
  const stringValues =
    transform.indexOf(",") === -1 ? transform.split(" ") : transform.split(",");
  return stringValues.map((v) => Number(v.trim()));
}

function matrixFromTransform(transform) {
  if (!transform) return null;

  if (transform.indexOf("matrix") !== -1) {
    transform = transform.replace("matrix(", "").replace(")", "");

    const values = parseTransform(transform);
    // console.log("values", transform, "=>", values);
    return Matrix.from.apply(this, values);
  }

  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("transform", transform);
  // console.log(transform, "svg.transform", svg.transform)
  const matrix = Matrix.fromSVGTransformList(svg.transform.baseVal);
  // console.log("=>", matrix)
  return matrix;
}

console.log("notes", self.notes);

self.saveNote = (id, value) => {
  self.notes[id] = value;
  localStorage.setItem(notesKey, JSON.stringify(self.notes));
  self.refreshFavoriteList();
};

const polygonOptions = {
  weight: 2,
  color: "#777",
  stroke: true,
  opacity: 0,
  fill: true,
  fillColor: "#777",
  fillOpacity: 0,
};
const selectedPolygonOptions = {
  ...polygonOptions,
  opacity: 1,
  fillOpacity: 0.2,
  // color: '#689F38',
  // fillColor: '#689F38',
  color: "#165E89",
  fillColor: "#165E89",
};
export const errorPolygonOptions = {
  ...polygonOptions,
  opacity: 0.5,
  fillOpacity: 0.2,
  color: "#FF0000",
  fillColor: "#FF0000",
};
export const validPolygonOptions = {
  ...polygonOptions,
  opacity: 0.5,
  fillOpacity: 0.2,
  color: "#00FF00",
  fillColor: "#00FF00",
};

self.initSidebar = function (options = {}) {
  console.log("self", self);
  trackScreen("home"); // Initial screen to track

  function onOpen(screen) {
    console.log("open", screen);
    if (options.onOpen) options.onOpen(screen);
  }

  let sidebar = L.control
    .sidebar("sidebar", {
      autoPan: self.params.sidebarAutoPan,
      position: self.params.isTable ? "right" : "left",
      closeable: self.params.isTable ? false : true,
      trackScreen,
      onOpen,
    })
    .addTo(self.map);
  self.sidebar = sidebar;

  sidebar.onOpen = onOpen;

  // Init special links
  $(".sidebar-menu-link, .home-menu-link").click(function (e) {
    if (!this.hash) {
      // External link, let it open
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    const screen = this.hash.slice(1);
    sidebar.open(screen);
  });
  $(".fullscreen-menu-link").click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    const screen = this.hash.slice(1);
    sidebar.open(screen);
  });
  sidebar.open(self.debug.initialScreen || "home");
};

function createInfoBox() {
  // method that we will use to update the control based on feature properties passed
  let infoRoot = null;
  return function (props = {}) {
    console.log("createInfoBox", props);
    infoRoot = render(
      <InfoBox
        program={props.events}
        exhibitors={props.stands}
        pois={props.pois}
        firstEvent={props.firstEvent}
        firstExhibitor={props.firstExhibitor}
      />,
      this._div,
      infoRoot
    );
  };
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function rotate(cx, cy, x, y, angle) {
  let radians = (Math.PI / 180) * angle,
    cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = cos * (x - cx) + sin * (y - cy) + cx,
    ny = cos * (y - cy) - sin * (x - cx) + cy;
  return [nx, ny];
}

function initStands(exhibitors) {
  let standsById = {};

  exhibitors.forEach((stand) => {
    stand.stands.forEach((s) => {
      s = s.toUpperCase();
      if (!standsById[s]) standsById[s] = [];
      standsById[s].push(stand);
    });
  });

  self.allStands = exhibitors;
  self.standsById = standsById;
  console.log("self.standsById", self.standsById);
}

function elementsByLocation(elements) {
  const byId = {};

  // console.log("program", allEvents)
  for (let el of elements) {
    const loc = el.locationId.replace(/ /g, "").toUpperCase();
    if (!byId[loc]) byId[loc] = [];
    byId[loc].push(el);
  }
  return byId;
}

function initProgram(program) {
  self.allEvents = program;
  self.eventsById = elementsByLocation(program);
}

function initPOI(pois) {
  self.allPois = pois;
  self.poiById = elementsByLocation(pois);
}

self.currentPopup = null;

self.renderPopup = function (popup) {
  const el = $("#popup-container");
  // el.css('display', 'none')

  function onClose(evt) {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    el.removeClass("visible");
  }

  $("#popup-container")
    .empty()
    .unbind("click")
    .click((evt) => {
      evt.stopPropagation();
      $("#popup-container").removeClass("visible");
    });

  render(React.cloneElement(popup, { onClose }), el[0]);

  setTimeout(() => el.addClass("visible"), 10);
};

self.showExhibitor = function (exhibitor) {
  console.log("showExhibitor", exhibitor);
  if (exhibitor) {
    trackEvent("exhibitor", exhibitor.name || exhibitor.title);
  }
  self.renderPopup(<ExhibitorPopup exhibitor={exhibitor} />);
};

self.showEvent = function (event) {
  console.log("showEvent", event);
  if (event) {
    trackEvent("event", event.title || event.name);
  }
  self.renderPopup(<EventPopup event={event} />);
};

function queryStringFor(obj) {
  let str = [];
  for (let key in obj) {
    const value = obj[key];
    str.push(key + "=" + (value ? encodeURIComponent(obj[key]) : ""));
  }
  return "?" + str.join("&");
}

function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

self.toggleFavorite = function (eventKey) {
  console.log("toggleFavorite " + eventKey);
  // var favoriteEl = $('[data-id="' + eventKey + '"] .exposant-favorite')

  let event = self.allEvents.find((evt) => evt.key === eventKey);
  console.log("toggleFavorite event", event);

  if (!self.favorites[eventKey]) {
    // Only monitor favorite adds
    if (!event) {
      trackEvent("favorite_exhibitor", eventKey);
    } else {
      trackEvent("favorite_event", event.title);
    }
  }

  if (self.favorites[eventKey]) {
    delete self.favorites[eventKey];
    // favoriteEl.removeClass("active")

    if (event) {
      if (window.NativeCode) {
        const date = event.jour + " " + event.startTime;
        const title = `${event.startTime}, ${event.salle}`;
        const body = event.title;
        NativeCode.removeNotification(event.key, title, body, date);
      } else if (isIOS()) {
        console.log("call", `dpmap://favorites/${event.key}/remove`);
        window.location = `dpmap://favorites/${event.key}/remove`;
      }
    }
  } else {
    self.favorites[eventKey] = true;
    // favoriteEl.addClass("active")

    if (event) {
      if (window.NativeCode) {
        const date = event.jour + " " + event.startTime;
        const title = `${event.startTime}, ${event.salle}`;
        const body = event.title;
        NativeCode.addNotification(event.key, title, body, date);
      } else if (isIOS()) {
        const qs = queryStringFor({
          title: event.title,
          subtitle: event.subtitle,
          salle: event.salle,
          jour: event.jour,
          startTime: event.startTime,
        });
        console.log("call", `dpmap://favorites/${event.key}/add${qs}`);
        window.location = `dpmap://favorites/${event.key}/add${qs}`;
      }
    }
  }
  localStorage.setItem(favoritesKey, JSON.stringify(self.favorites));

  self.refreshFavoriteList();
  if (self.refreshInfo) self.refreshInfo();

  // Remove focus
  // $("event-" + eventKey).blur()
};

self.highlightFeature = function (e) {
  let layer = e.target;
  if (!layer) return;

  self.updateLinkedLayers(layer, (l) => {
    l.setStyle({
      opacity: 1,
      fillOpacity: 0.2,
    });
  });

  if (!L.Browser.ie && !L.Browser.opera) {
    layer.bringToFront();
  }
  // info.update(layer.props);
};
self.resetHighlight = function (e) {
  let layer = e.target;
  if (!layer) return;
  // console.log("resetHighlight", layer);

  self.updateLinkedLayers(layer, (l) => {
    l.setStyle(l.defaultOptions);
  });

  // info.update();
};
self.setStartStand = function (startPos, startIconUrl) {
  let startEl = $("#" + startPos);
  if (startEl.length > 0) {
    // Fetch bounding box of any SVG shape
    // var box = startEl[0].getBBox()
    // self.startPos = {
    //   x: box.x + box.width / 2,
    //   y: box.y + box.height / 2
    // }
    console.log("startEl[0]", startEl[0]);
    if (startEl[0].tagName === "circle") {
      self.startPos = {
        x: Number(startEl.attr("cx")),
        y: Number(startEl.attr("cy")),
      };
    } else {
      self.startPos = {
        x: Number(startEl.attr("x")) + Number(startEl.attr("width")) / 2,
        y: Number(startEl.attr("y")) + Number(startEl.attr("height")) / 2,
      };
    }
    console.log("startPos", self.startPos);

    //         y  , x
    let mx = self.startPos.x * self.ratio + self.ratioDx;
    let my = self.startPos.y * self.ratio + self.ratioDy;

    const pulseIcon = L.icon.pulse({
      iconUrl: startIconUrl,
      // className: "a-icon-grow",

      color: "red",
      iconSize: [25, 25], // size of the icon
    });

    L.marker(new L.LatLng(-my, mx), {
      icon: pulseIcon,
      zIndexOffset: 1000,
    }).addTo(self.map);
    let youAreHereIcon = L.icon({
      iconUrl: startIconUrl,
      // className: "a-icon-grow",

      iconSize: [50, 50], // size of the icon
      iconAnchor: [25, 25], // point of the icon which will correspond to marker's location
    });

    L.marker(new L.LatLng(-my, mx), {
      icon: youAreHereIcon,
      zIndexOffset: 1001,
    }).addTo(self.map);
  } else {
    console.warn("No start position found in SVG");
  }
};

self.latLongOfPoint = function (p) {
  let x = p.x * self.ratio + self.ratioDx;
  let y = p.y * self.ratio + self.ratioDy;
  return new L.LatLng(-y, x);
};

self.clearPath = function () {
  if (self.currentPath) {
    console.log("clear path");
    self.currentPath.clear();
    self.currentPath = null;
  }
};

self.showPath = function (start, goal, callback) {
  self.clearPath();

  console.log("go to", goal);
  // console.time("path-finding")
  let path = self.segments.findPath(start, goal);
  // console.timeEnd("path-finding")
  if (!path) {
    // randomPath()
    if (callback) callback(false);
    return false;
  }

  // Adapt zoom to path

  function latLng(p) {
    let x = p.x * self.ratio + self.ratioDx;
    let y = p.y * self.ratio + self.ratioDy;
    return new L.LatLng(-y, x);
  }

  self.map.fitBounds(
    path.map(self.latLongOfPoint),
    //     [
    //     latLng(start), latLng(goal)
    // ]
    {
      paddingTopLeft: L.point(250, 100),
      paddingBottomRight: L.point(100, 100),
      maxZoom: self.params.zoomToMaxZoom,
      animate: true,
    }
  );

  let DOT_DIST = 4;

  let isStopped = false;
  let markers = [];
  let polyline = null;

  setTimeout(() => {
    if (isStopped) return;

    let dots = [];
    function lerp(ratio, start, end) {
      return start + (end - start) * ratio;
    }
    function addDots(start, end) {
      let dist = dist_between(start, end);
      // console.log("dist", dist, start, end)
      if (dist < DOT_DIST) dots.push(end);
      else {
        let n = Math.round(dist / DOT_DIST);
        for (let i = 1; i <= n; i++) {
          dots.push({
            x: lerp(i / n, start.x, end.x),
            y: lerp(i / n, start.y, end.y),
          });
        }
      }
    }
    // console.log("dots", dots)
    dots.push(path[path.length - 1]);
    for (let i = path.length - 1; i > 0; i--) {
      addDots(path[i], path[i - 1]);
    }
    // markers.addLayer(newMarker(dot.x, dot.y));
    function addMarker(x, y, icon) {
      const pulseIcon = L.icon.pulse({
        color: "blue",
        iconSize: [5, 5], // size of the icon
      });
      let markerPulse = self.newMarker(x, y, pulseIcon);
      self.map.addLayer(markerPulse);
      markers.push(markerPulse);

      let marker = self.newMarker(x, y, icon);
      self.map.addLayer(marker);
      markers.push(marker);
    }
    function addPolyline(dots) {
      //Define an array of Latlng objects (points along the line)
      let polylinePoints = dots.map(self.latLongOfPoint);
      let polylineOptions = {
        color: "#165E89",
        className: "a-path-dash",
        weight: 3,
        opacity: 1,
        smoothFactor: 1,
      };

      if (polyline) {
        polyline.setLatLngs(polylinePoints);
      } else {
        polyline = new L.Polyline(polylinePoints, polylineOptions);
        self.map.addLayer(polyline);
      }

      // if (polyline) self.map.removeLayer(polyline)
      // polyline = new L.Polyline(polylinePoints, polylineOptions);
      // self.map.addLayer(polyline)
    }
    let n = 0;
    // addMarker(dots[0].x, dots[0].y)
    addMarker(dots[dots.length - 1].x, dots[dots.length - 1].y);

    let dotIcon = L.icon({
      iconUrl: "images/dot.png",

      iconSize: [8, 8], // size of the icon
      iconAnchor: [4, 4], // point of the icon which will correspond to marker's location
      //popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    });
    let dotFreq = 50;
    if (dots.length > 10) {
      dotFreq = Math.round(500 / dots.length);
    }

    // Show goal neighbors
    // var youAreHereIcon = L.icon({
    //     iconUrl: "images/ici.svg",

    //     iconSize:     [25, 25], // size of the icon
    //     iconAnchor:   [12.5, 12.5], // point of the icon which will correspond to marker's location
    //     //popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    // });
    // goal.neighbors.forEach(function(p){
    //     addMarker(p.x, p.y, youAreHereIcon)
    // })

    function addDot() {
      if (isStopped) {
        console.log("blocked by isStopped");
        return;
      }
      addPolyline(dots.slice(0, n));
      // addMarker(dots[n].x, dots[n].y, dotIcon)
      n++;
      if (n > dots.length) {
        if (callback) {
          callback(true);
        }
      } else {
        setTimeout(addDot, dotFreq);
      }
    }
    addDot();
  }, 300);

  self.currentPath = {
    clear() {
      self.currentPath = null;
      isStopped = true;
      if (polyline) {
        self.map.removeLayer(polyline);
        polyline = null;
      }
      markers.forEach((marker) => {
        self.map.removeLayer(marker);
      });
    },
  };

  return true;
};

function autoHideKeyboard() {
  if (window.hideKeyboard) {
    window.hideKeyboard();
  }
}

self.selectLayer = function (layer, firstExhibitor, firstEvent) {
  autoHideKeyboard();
  self.unselectLayer();

  if (!layer) {
    self.info.update();
    return;
  }

  console.log("selectLayer", layer);
  if (!config.localizedPopup) {
    self.info.update({ ...layer.props, firstExhibitor, firstEvent });
  }

  if (config.pathfinding && self.params.startStand && self.startPos) {
    // console.log("selectLayer", layer)
    // Find and add candidate positions
    // x = x*ratio + ratioDx
    // y = y*ratio + ratioDy
    // return new L.LatLng(-y, x)
    let points = layer._latlngs[0].map((p) => {
      return {
        x: (p.lng - self.ratioDx) / self.ratio,
        y: (-p.lat - self.ratioDy) / self.ratio,
      };
    });

    // Use entree points instead ?
    const startElement = document.getElementById(
      "entree-" + layer.props.stand.toLowerCase()
    );
    console.log("search for start element", startElement);
    if (startElement) {
      // Replace with new start location
      const el = $(startElement);
      const matrix = matrixFromTransform(el.attr("transform"));

      function makePoint(x, y) {
        if (matrix) {
          return matrix.applyToPoint(x, y);
        }
        return { x, y };
      }

      const x = Number(el.attr("x"));
      const y = Number(el.attr("y"));
      const width = Number(el.attr("width"));
      const height = Number(el.attr("height"));

      points = [
        makePoint(x, y),
        makePoint(x + width, y),
        makePoint(x + width, y + height),
        makePoint(x, y + height),
      ];
      console.log("new points", points);
    }
    // if ()

    let middlePoints = [];

    let dist = Infinity;
    let bestPoint = null;
    let start = self.segments.closestPointFor(self.startPos);

    function computeDistance(start, goal) {
      let path = self.segments.findPath(start, goal);
      if (!path) return Infinity;
      let d = 0;
      for (let i = 1; i < path.length; i++) {
        d += dist_between(path[i - 1], path[i]);
      }
      return d;
    }

    for (let i = 0; i < points.length; i++) {
      let p1 = points[i];
      let p2 = points[(i + 1) % points.length];
      let p = { x: (p1.x + p2.x) / 2, y: (p1.y + p2.y) / 2 };

      // middlePoints.push(p)
      let inter = self.segments.closestPointFor(p);
      // self.segments.findPath(start, goal)
      // const entryDist = dist_between(p, inter)
      let d = computeDistance(start, inter) + dist_between(p, inter) * 6;
      if (d < dist) {
        dist = d;
        bestPoint = inter;
      }
      // self.map.addLayer(self.newMarker(inter.x, inter.y))
      // var segment = self.segments.closestSegmentFor(p)
      // console.log("segment", segment)
      // self.map.addLayer(self.newMarker(segment.segment.p1.x, segment.segment.p1.y))
      // self.map.addLayer(self.newMarker(segment.segment.p2.x, segment.segment.p2.y))
      // var inter = posToSegment(p, segment.segment.p1, segment.segment.p2)
      // self.map.addLayer(self.newMarker(inter.x, inter.y))
      // self.map.addLayer(self.newMarker((p1.x + p2.x)/2, (p1.y + p2.y)/2))
    }
    // self.map.addLayer(self.newMarker(bestPoint.x, bestPoint.y))
    self.showPath(start, bestPoint, () => {});
  } else {
    self.zoomToFeature(layer);
  }

  // window.location.hash = layer.props.stand
  if (history.replaceState && !config.isTable && !config.isMobile) {
    let newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?stand=" +
      layer.props.stand;
    window.history.replaceState({ path: newurl }, "", newurl);
  }

  // Get all layers

  self.selectedStand = layer.props.stand;
  self.updateLinkedLayers(layer, (l) => {
    console.log("updateLinkedLayers", l);
    l.defaultOptions = selectedPolygonOptions;
    l.setStyle(l.defaultOptions);
  });

  // window.history.replaceState(null, null, 'select');
};

self.unselectLayer = function () {
  self.clearPath();

  if (self.selectedStand) {
    // Get corresponding stands
    self.updateLinkedLayers(self.layersById[self.selectedStand], (l) => {
      l.defaultOptions = polygonOptions;
      l.setStyle(l.defaultOptions);
    });
  }
  self.selectedStand = null;
  // window.location.hash = ""
  // window.history.replaceState(null, null, 'unselect');
};

self.zoomToFeature = function (layer) {
  self.map.fitBounds(layer.getBounds(), {
    maxZoom: self.params.zoomToMaxZoom || 9,
  });
};

self.getLinkedLayers = function (layer) {
  let stands = self.standsById[layer.props.stand];
  if (!stands) {
    return [layer];
  }
  let ids = [];
  stands.forEach((exp) => {
    exp.stands.forEach((id) => {
      if (
        ids.indexOf(id) == -1 &&
        (self.layersById[id] || self.layersById[id.toUpperCase()])
      ) {
        ids.push(id);
      }
    });
  });
  return ids.map((id) => {
    return self.layersById[id] || self.layersById[id.toUpperCase()];
  });
};

self.updateLinkedLayers = function (layer, f) {
  self.getLinkedLayers(layer).forEach(f);
};

self.reloadIfInactive = function (duration) {
  let timeout = null;
  function resetTimer() {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      window.location.reload();
    }, duration * 1000);
  }
  $(document).on(
    "mousedown mousemove keydown click touchstart touchmove touchend",
    (e) => {
      // console.log(e.type)
      resetTimer();
    }
  );
};

self.init = function (params) {
  self.params = params;
  self.debug = params.debug || {};

  console.log("favorites", self.favorites);
  initStands(self.params.exhibitors);
  initProgram(self.params.program);
  initPOI(self.params.pois || []);
  const layersById = {};
  self.layersById = layersById;

  self.startPos = null;
  self.isTable = !!params.isTable;

  if (self.isTable) {
    // Disable all links
    $("a[href^='http'], a[href^='tel']").click((e) => {
      e.preventDefault();
    });

    if (params.inactivityTimeForReload) {
      self.reloadIfInactive(params.inactivityTimeForReload);
    }
  }

  // Using leaflet.js to pan and zoom a big image.
  // See also: http://kempe.net/blog/2014/06/14/leaflet-pan-zoom-image.html
  // create the slippy map

  const zoomFactor = 1.5;
  L.CRS.CustomZoom = L.extend({}, L.CRS.Simple, {
    scale(zoom) {
      // console.log("zoom", zoom, Math.pow(zoomFactor, zoom));
      return Math.pow(zoomFactor, zoom);
    },

    zoom(scale) {
      // console.log("scale", scale, Math.log(scale) / Math.log(zoomFactor));
      return Math.log(scale) / Math.log(zoomFactor);
    },
  });

  // Init correct size for map
  let mapContainer = document.getElementById("image-map");
  if (params.isTable) {
    L.DomUtil.addClass(mapContainer, "sidebar-right-map");
  }
  if (!params.sidebarVisible) {
    L.DomUtil.addClass(mapContainer, "sidebar-hidden");
  }

  const maxZoom = config.maxZoom || 7;
  L.control.maxZoom = maxZoom;
  console.log("maxZoom", maxZoom);
  let map = L.map("image-map", {
    minZoom: 1,
    maxZoom,
    zoomSnap: 0,
    center: [0, 0],
    zoom: 0,
    zoomControl: false,
    crs: L.CRS.CustomZoom,
    attributionControl: false,
  });
  self.map = map;
  map.on("zoomstart", autoHideKeyboard);
  map.on("dragstart", autoHideKeyboard);
  map.on("preclick", autoHideKeyboard);

  console.log("size of map", $(mapContainer).width(), $(mapContainer).height());
  // dimensions of the image
  let w = $(mapContainer).width() * Math.pow(1.5, maxZoom - 1) * 0.9;
  let h = $(mapContainer).height() * Math.pow(1.5, maxZoom - 1) * 0.9;
  // var h = w*0.55;
  let url = params.imageUrl;
  // calculate the edges of the image, in coordinate space
  let southWest = map.unproject([0, h], map.getMaxZoom());
  let northEast = map.unproject([w, 0], map.getMaxZoom());
  let bounds = new L.LatLngBounds(southWest, northEast);
  // console.log("northEast", northEast)
  // console.log("bounds", bounds)

  map.fitBounds(bounds, {
    animate: false,
  });

  // add the image overlay,
  // so that it covers the entire map
  L.imageOverlay(url, bounds).addTo(map);

  let svgSizes = $("#text-map>svg")[0].getAttribute("viewBox").split(" ");
  svgSizes = svgSizes.map((n) => {
    return Number(n);
  });
  // console.log("svgSizes", svgSizes)

  let ratioX = northEast.lng / svgSizes[2];
  let ratioY = -southWest.lat / svgSizes[3];

  let ratio = Math.min(ratioX, ratioY);
  let ratioDx = (northEast.lng - svgSizes[2] * ratio) / 2;
  let ratioDy = (-southWest.lat - svgSizes[3] * ratio) / 2;
  self.ratio = ratio;
  self.ratioDx = ratioDx;
  self.ratioDy = ratioDy;

  function newMarker(x, y, icon) {
    var x = x * self.ratio + self.ratioDx;
    var y = y * self.ratio + self.ratioDy;
    if (icon) {
      return L.marker(new L.LatLng(-y, x), { icon });
    }
    return L.marker(new L.LatLng(-y, x));
  }
  self.newMarker = newMarker;

  let info = L.control({
    position: self.isTable ? "topleft" : "topright",
  });
  self.info = info;

  info.onAdd = function (map) {
    this._div = L.DomUtil.create("div", "info"); // create a div with a class "info"
    this.update();
    return this._div;
  };

  if (params.embedMobile) {
    info.update = function (selection = {}) {
      console.log("select", selection);
      function removePoly(e) {
        const { polygon, ...rest } = e;
        return rest;
      }
      const msg = {
        type: "select",
        stand: selection.stand,
        events: (selection.events || []).map(removePoly),
        stands: (selection.stands || []).map(removePoly),
      };
      console.log("msg", msg);
      window.postMessage(JSON.stringify(msg), "*");
    };
  } else {
    info.update = createInfoBox();
  }

  let toShow =
    getParameterByName("show") ||
    getParameterByName("booth") ||
    getParameterByName("stand") ||
    window.location.hash.replace(/^#/, "");
  if (toShow) toShow = toShow.toUpperCase();
  console.log("toShow", toShow);

  if (params.startStand) {
    self.setStartStand(params.startStand, params.startIconUrl);
  }

  self.selectedStand = null;

  map.on("click", (e) => {
    // Unselect current selection if needed...
    self.unselectLayer();
    info.update();
  });

  function getPolygonFor(el) {
    let tagName = el.tagName;
    el = $(el);
    // console.log(el, tagName)

    const matrix = matrixFromTransform(el.attr("transform"));

    function makePoint(x, y) {
      if (matrix) {
        let transformed = matrix.applyToPoint(x, y);
        x = transformed.x;
        y = transformed.y;
      }
      // var xy = rotate(cx, cy, x, y, angle)
      x = x * ratio + ratioDx;
      y = y * ratio + ratioDy;
      return new L.LatLng(-y, x);
    }

    if (tagName == "rect") {
      let x = Number(el.attr("x"));
      let y = Number(el.attr("y"));
      let width = Number(el.attr("width"));
      let height = Number(el.attr("height"));
      let angle = Number(el.attr("angle") || 0);

      var cx = x + width / 2;
      var cy = y + height / 2;
      var polygonPoints = [
        makePoint(x, y),
        makePoint(x + width, y),
        makePoint(x + width, y + height),
        makePoint(x, y + height),
      ];
      return new L.Polygon(polygonPoints, polygonOptions);
    } else if (tagName == "polygon" || tagName == "polyline") {
      let points = el.attr("points").trim().split(" ");

      if (points[0].indexOf(",") !== -1) {
        // Classic comma separated values
        points = $.map(points, (it) => {
          return it.trim().split(",");
        });
      } else {
        // Group 2 by 2
        points = makeBulk(points);
      }

      var polygonPoints = $.map(points, (arr) => {
        if (matrix) {
          let transformed = matrix.applyToPoint(arr[0], arr[1]);
          x = transformed.x;
          y = transformed.y;
        }
        // console.log(arr)
        var x = ratioDx + Number(arr[0]) * ratio;
        var y = ratioDy + Number(arr[1]) * ratio;
        return new L.LatLng(-y, x);
      });
      return new L.Polygon(polygonPoints, polygonOptions);
    } else if (tagName == "circle") {
      var cx = Number(el.attr("cx"));
      var cy = Number(el.attr("cy"));
      let radius = Number(el.attr("r"));

      if (matrix) {
        let transformed = matrix.applyToPoint(cx, cy);
        cx = transformed.x;
        cy = transformed.y;

        // Cheat for radius...
        let zero = matrix.applyToPoint(0, 0);
        let radiusPoint = matrix.applyToPoint(radius, 0);
        radius = radiusPoint.x - zero.x; // TODO : Might not work with rotations...
      } else {
        radius = makePoint(radius, 0).lng - makePoint(0, 0).lng;
      }

      return new L.Circle(makePoint(cx, cy), { radius, ...polygonOptions });
    }
    return null;
  }

  function getBoundsFor(el) {
    return getPolygonFor(el).getBounds();
  }

  // popup simple sur la sélection
  function assignPolygons(polygon, elements, type) {
    if (elements) {
      let html = "";
      for (let el of elements) {
        el.polygon = polygon;
        if (config.localizedPopup && type === "stand") {
          html += `
          <div style="text-align: center;">`;
          if (el.logo) {
            html += `
            <img class="exposant-logo" src="./assets/logos/${el.logo}" style="width: 100px" />`;
          } else {
            html += `
            <img class="exposant-logo" src="./assets/images/logo-placeholder.png" style="width: 100px" />
            <h3 class="name">${el.name}</h3>`;
          }
          html += `
          </div>
          `;
        }
        // console.log("polygon.bindPopup", polygon.bindPopup)
      }
      if (html) polygon.bindPopup(html);
    }
  }

  function addStand(el) {
    // console.log(el);

    let tagName = el.tagName;
    el = $(el);
    let id = el.attr("id");

    if (!id) {
      console.warn("missing id on", el);
    }

    // TODO : weird id generation
    // _x34_7_B => 47B
    if (id) {
      id = id.replace("_x3", "").toUpperCase(); // .split("_").join("")
    }

    if (config.debug && config.debug.showSVGIds) console.log("SVG id", id);

    if (tagName == "g") {
      el.children().each((idx, el) => {
        try {
          addStand(el);
        } catch (e) {
          console.error("ERROR - failed to add stand for", el, e);
        }
      });
      return;
    }
    let polygon = getPolygonFor(el[0]);

    if (polygon) {
      polygon.on({
        mouseover: self.highlightFeature,
        mouseout: self.resetHighlight,
        click(e) {
          // Program event to avoid propagation effect (unselect)
          setTimeout(() => {
            self.selectLayer(e.target);
          }, 0);
          e.originalEvent.preventDefault();
          e.originalEvent.stopPropagation();
          return false;
        },
      });
      // console.log("polygon with id", id)
      const stands = id && self.standsById[id];
      const events = id && self.eventsById[id];
      const pois = id && self.poiById[id];
      polygon.props = {
        stands,
        events,
        pois,
        stand: id,
      };
      if (config.debug.showLayers) console.log("add layer", id);
      if (id) {
        layersById[id] = polygon;
      }
      const options = polygonOptions;
      assignPolygons(polygon, stands, "stand");
      assignPolygons(polygon, events);
      assignPolygons(polygon, pois);

      polygon.defaultOptions = options;
      map.addLayer(polygon);
    }
  }
  $.each(params.svgGroups, (groupIdx, group) => {
    $("#" + group).each((idx, el) => {
      addStand(el);
    });
  });

  let missingEventLocs = {};
  self.allEvents.forEach((event) => {
    if (!event.polygon && !missingEventLocs[event.id]) {
      console.warn(
        'invalid location "' +
          event.locationId +
          '" - "' +
          event.location +
          '" for',
        event.id,
        event.title
      );
      missingEventLocs[event.id] = true;
    }
  });

  function checkHasPolygon(elements) {
    for (let el of elements) {
      if (!el.polygon) {
        console.warn(
          'CHECK_HAS_POLYGON - invalid location "' +
            el.locationId +
            '" - "' +
            el.location +
            '" for',
          el.id,
          el.name || el.title
        );
      }
    }
  }

  checkHasPolygon(self.allStands);
  checkHasPolygon(self.allPois);

  info.addTo(map);

  // console.log("layersById", layersById)

  // Show selected stand
  if (!config.isTable && toShow && toShow.length > 0) {
    self.selectLayer(layersById[toShow]);
  }

  // tell leaflet that the map is exactly as big as the image
  //map.setMaxBounds(bounds);

  let zoomHome = L.Control.zoomHome({
    position: self.isTable ? "topright" : "topleft",
    // extraZones: [
    //     {name: "P7", title: "Palais 7", bounds: getBoundsFor($("#palais07")[0])},
    //     {name: "P11", title: "Palais 11", bounds: getBoundsFor($("#palais11")[0])},
    // ]
  });
  zoomHome.addTo(map);

  // initSidebar();

  // Init search
  // var userList = new List('exposants-list', {
  //   valueNames: ['name', 'stand']
  // });

  // console.log("L.Browser.touch ?", L.Browser.touch)
  $(".leaflet-control").each((idx, container) => {
    // console.log("patch", container)
    if (!L.Browser.touch) {
      L.DomEvent.disableClickPropagation(container).disableScrollPropagation(
        container
      );
    } else {
      L.DomEvent.disableClickPropagation(container);
    }
  });

  if (params.startStand) {
    initSegments(self);
    $("body").addClass("hide-favorites");
    $('[data-favorite="true"]').css("display", "none");
    // showSegmentPoints();
  }
  // randomPath()
};

export default self;
