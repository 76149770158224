import { h, Component } from 'preact';

import MapModule from '../core/map'
import parcours from "../../data/parcours.json"
// import Collapsible from 'react-collapsible'

// import listItemTpl from '../templates/list-item.html'
// import listEventTpl from '../templates/list-event.html'
// import { renderExhibitor } from '../core/map/exhibitors'
// import { renderEvent } from '../core/map/program'
import List from './list'
import { trackEvent } from '../core/analytics';

class StaticList extends Component {

  shouldComponentUpdate(){
    return false
  }

  render(){
    return <List {...this.props} />
  }
}

export default class Parcours extends Component {

  constructor(props){
    super(props)
    this.state = {
      open: false
    }
    this.refs = {}
  }

  computeHeight = (name) => {
    if (name !== this.state.open || !this.refs[name]) return 0

    return this.refs[name].children[0].offsetHeight
  }

  toggle = (name) => {
    if (this.state.open !== name) {
      // Opening this one
      trackEvent("parcours", name)
    }
    this.setState({
      open: this.state.open === name ? false : name
    })
  }

  render(){
    const { parcours:parcoursMap } = this.props
    const { open } = this.state
    // console.log("parcoursMap", parcoursMap)
    return (
      <div style={{minHeight: '100.1%'}}>
        <div class="c-accordeon">
          {parcours.map(({name, key, picto}) => [
            <div class="c-accordeon__header" onClick={() => this.toggle(name)}>
              <img class="picto" src={"./assets/images/pictos/" + picto} />
              <i class={"fa fa-chevron-" + (open === name ? "down" : "right")} />
              {name}
            </div>,
            <div ref={el => this.refs[name] = el} class="c-accordeon__content" style={{height: this.computeHeight(name)}}>
              <StaticList {...parcoursMap[key || name]} delayRender={true} />
            </div>
            // <Collapsible trigger="Lieu du congrès">
            //   <img src="./assets/images/lieu.svg" style="width: 100%;" />
            //   <p><span>Situ&eacute;e dans le 14e arrondissement, dans un parc de 34 hectares, la Cit&eacute; internationale universitaire de Paris est une fondation priv&eacute;e, reconnue d&rsquo;utilit&eacute; publique, propri&eacute;t&eacute; par donation des universit&eacute;s parisiennes. Elle est compos&eacute;e de 40 maisons pratiquant le brassage des nationalit&eacute;s et des disciplines pour favoriser les &eacute;changes. Chaque ann&eacute;e, la Cit&eacute; internationale accueille 12 000 &eacute;tudiants, chercheurs, artistes et sportifs de haut niveau, de plus de 140 nationalit&eacute;s, qui sont invit&eacute;s &agrave; s&rsquo;investir dans la gouvernance, la vie collective et le d&eacute;veloppement de l&rsquo;institution.</span></p>
            // </Collapsible>
          ])}
        </div>
      </div>
    )
  }
}

//<li class="exposant-entry event event--header" style="background-color: #00242f; color: white;" data-always-visible="true">
//  <div class="event--header__date">{{name}}</div>
//</li>