import { h } from "preact";
import { useState } from "preact/hooks";
import { requireName } from "../core/require-name";
import { injectParams, replaceUriValues } from "./QuizScreen";

const Tab = ({ id, children, active, onSelect }) => (
	<div class={`event-tab event-tab--${id} ${active ? "active" : ""}`} onClick={() => onSelect(id)}>
		{children}
	</div>
);

const TabMenu = ({ activeTab, tabs, onSelect }) => {
	return (
		<div class="event-tabs small">
			{tabs.map(({ name, label }) => (
				<Tab key={name} id={name} active={name === activeTab} onSelect={onSelect}>
					{label}
				</Tab>
			))}
		</div>
	);
};

export const MultiIframeScreen = requireName(({ user, tabs, iframeProps }) => {
	const [activeTab, setActiveTab] = useState(tabs[0].name);
	return (
		<div class="sidebar-content-list">
			<TabMenu tabs={tabs} activeTab={activeTab} onSelect={setActiveTab} />
			{tabs.map((tab) => {
				let url = replaceUriValues(tab.url, user);
				if (tab.injectUser) {
					url = injectParams(url, user);
				}
				return (
					<div key={tab.name} class={"event-tab-content small" + (activeTab === tab.name ? " active" : "")}>
						<iframe
							scrolling
							style={{ width: "100%", height: "100%", border: 0 }}
							src={url}
							{...iframeProps}
						/>
					</div>
				);
			})}
		</div>
	);
});
