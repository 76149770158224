import config from "../config";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBK5WWnMYzTk4df-r9hD2OTb_xBXlSL_MI",
  authDomain: "coec-474d7.firebaseapp.com",
  databaseURL: "https://coec-474d7.firebaseio.com",
  projectId: "coec-474d7",
  storageBucket: "coec-474d7.appspot.com",
  messagingSenderId: "487353003483",
  appId: "1:487353003483:web:e7ef7b18e82911f9d38bfe",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging();

async function initNotifications() {
  console.log("initNotifications");
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BKbC_39OjUNpfRZYCc8El-XvojuyH5nfwAEacosBsdsnkSaquKrbU05Wvez8Atz7JqwgEEDIpjaDk549HOFYgkg",
    });

    if (currentToken) {
      // Send the token to your server and update the UI if necessary
      console.log("Found token", currentToken);

      // Send to server
      fetch(
        "https://app.appcraft.events/api/mPWnLdY5W1NR8I/notifications/register",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: currentToken }),
        }
      )
        .then((res) => res.json())
        .then(console.log)
        .catch(console.error);
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (e) {
    console.warn("ERROR", e);
  }

  onMessage(messaging, (payload) => {
    console.log("Message received. ", payload);
    if (window.onNotification) {
      window.onNotification(payload.notification);
    }
  });
}

if (config.hasNotifs) {
  setTimeout(initNotifications, 5000);
}

export default app;
