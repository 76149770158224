import { h, Component } from 'preact';


let currentInput = null
let isKeyboardVisible = false

function showKeyboard(input){
  isKeyboardVisible = true
  if (input) currentInput = input;
  console.log("showKeyboard");
  $("#osk-container").addClass("visible")
}

function hideKeyboard(){
  isKeyboardVisible = false
  $("#osk-container").removeClass("visible")
}
window.showKeyboard = showKeyboard
window.hideKeyboard = hideKeyboard

function trigger(el, type){
  if ("createEvent" in document) {
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent(type, false, true);
    el.dispatchEvent(evt);
  }
  else
      el.fireEvent("on" + type);
}

function initKeys(keyboard){

  $("#osk-container li").click(function(e) {
    e.preventDefault()
    e.stopPropagation()

    if (!currentInput) return

    const $input = $(currentInput)

    const $key      = $(this)
    let character = $key.html().toUpperCase()

    function notify(){
      
      // const event = {target: currentInput}
      // if (currentInput._listeners && currentInput._listeners.input){
      //   currentInput._listeners.input(event)
      // } else {
        trigger(currentInput, "change")
        trigger(currentInput, "input")
        showKeyboard()
        // $input.trigger("input", event)

        // jQuery.event.simulate( "change", event.target, event );
      // }
    }

    if ($key.hasClass('osk-hide')) {
      hideKeyboard()
      $(currentInput).blur();
      return;
    } else if ($key.hasClass('osk-backspace')) {
				const inputValue = $input.val();
				$input.val(inputValue.substr(0, inputValue.length - 1));
        notify();
				return;
    } else if ($key.hasClass('osk-space')) {
				character = ' '
    }
    // var e = jQuery.Event("keypress");
    // e.chara = 'a';
    // $input.trigger(e);
    // $input
    // .focus()
    // .trigger("keypress") // you can trigger keypress like this if you need to..
    // .val(function(i,val){
    //   console.log("previous val", val)
    //   return val + character;
    // });
    $input.focus().val($input.val() + character);
    notify();
    // $input.trigger("change");
    // $input.change({
    //   target: currentInput
    // })
  })

}

export default class Keyboard extends Component {

  componentDidMount(){
    const $container = $("#keyboard-container")
    // function hideKeyboardIfOut(evt){
    //   console.log("isKeyboardVisible", isKeyboardVisible)
    //   console.log(evt.target)
    //   console.log($(evt.target).parents("#osk-container"))
    //   if (isKeyboardVisible && $(evt.target).parents("#osk-container").length == 0) {
    //       hideKeyboard()
    //   }
    // }

    // $("#keyboard-container").click(hideKeyboardIfOut)
    // $("#keyboard-container").on("touchstart", hideKeyboardIfOut)
    // $("#keyboard-container").on("mousedown", hideKeyboardIfOut)

    const keyboard = $("#osk-container")
    keyboard.click(function(e){
      e.stopPropagation()
      if (currentInput) $(currentInput).focus()
    })

    setTimeout(() => {
      initKeys(keyboard)

      // $("input").focus(e => {
      //   // e.preventDefault()
      //   // e.stopPropagation()
      //   currentInput = e.target
      //   showKeyboard()
      // })
    }, 500) // 500ms lag to be ensure everything is up and running
  }

  render() {
    // const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
    const letters1 = "azertyuiop 789".split("")
    const letters2 = "qsdfghjklm 456".split("")
    const letters3 = "@wxcvbn.← 123".split("")
    // const letters3 = "wxcvbn,;".split("")

    function key(l){
      switch(l){
        case " ": return <li class="osk-padding" />
        case "←": return <li class="osk-backspace"><i class="fa fa-long-arrow-left"></i></li>
        default: return <li key={l} class="osk-letter">{l}</li>
      }
    }
        // {numbers.map(key)}

    return (
      <ul id="osk-container">
        {letters1.map(key)}
        {letters2.map(key)}
        {letters3.map(key)}
        <li class="osk-space">espace</li>
        <li class="osk-hide osk-width-2">
          <i class="fa fa-long-arrow-down"></i>&nbsp;&nbsp;
          <i class="fa fa-keyboard-o"></i>
        </li>
        <li class="osk-padding" />
        <li class="osk-letter osk-width-2">0</li>
        <li class="osk-letter">.</li>
      </ul>
    )

    /*return (
      <ul id="osk-container">
        {numbers.map(n => <li key={n} class="osk-number">{n}</li>)}
        <li class="osk-backspace osk-last-item"><i class="fa fa-long-arrow-left"></i></li>

        <li class="osk-padding" />
        {letters1.map(l => <li key={l} class="osk-letter">{l}</li>)}
        <li class="osk-padding" />
        <li class="osk-padding" />

        <li class="osk-padding" />
        <li class="osk-padding" />
        {letters2.map(l => <li key={l} class="osk-letter">{l}</li>)}
        <li class="osk-padding" />
        
        <li class="osk-padding" />
        <li class="osk-padding" />
        <li class="osk-padding" />
        {letters3.map(l => <li key={l} class="osk-letter">{l}</li>)}
        <li class="osk-hide osk-last-item">masquer</li>
        <li class="osk-space osk-last-item">espace</li>
      </ul>
    )*/
  }
}
