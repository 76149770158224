import {errorPolygonOptions,validPolygonOptions  } from './index'
export default function initSegments(self) {

  var segments = new Segments()
  self.segments = segments
  if ($("#chemins").length == 0) {
    console.error("SVG id #chemins not found");
  }
  var paths = $("#chemins path")
  if (paths.length) {
    console.error("WARNING - ignoring " + paths.length + " paths in #chemins ! Only lines are allowed");
  }
  $("#chemins line").each(function (idx, el) {
    var sel = $(el)
    function numAttr(el, key) {
      return parseFloat(el.attr(key))
    }
    var p1 = new Point(numAttr(sel, "x1"), numAttr(sel, "y1"))
    var p2 = new Point(numAttr(sel, "x2"), numAttr(sel, "y2"))
    segments.add(new Segment(p1, p2))
  })
  segments.processGrid()

  function showSegments() {
    segments.segments.forEach(function (seg) {
      var segment = seg.segment
      var polylinePoints = [segment.p1, segment.p2].map(self.latLongOfPoint);
      var polylineOptions = {
        color: '#FF0000',
        weight: 2,
        opacity: 1,
        smoothFactor: 1,
      };
      const polyline = new L.Polyline(polylinePoints, polylineOptions);
      self.map.addLayer(polyline)
    })
  }

  function showSegmentPoints() {
    segments.points.forEach(function (p) {
      //         y  , x
      var x = p.x * self.ratio + self.ratioDx
      var y = p.y * self.ratio + self.ratioDy
      L.marker(new L.LatLng(-y, x))
        .on('click', function (e) {
          var start = segments.closestPointFor(self.startPos)  //randIdx(segments.points)
          var goal = p
          self.showPath(start, goal)
        })
        .addTo(self.map);
    })
  }
  
  if (self.debug.showPath) showSegments();
  if (self.debug.showPathPoints) showSegmentPoints();

  if (self.debug.showUnattainableStands && self.startPos) {
    function pathFor(layer) {
      if (!layer) return false
      var points = layer._latlngs[0].map(function (p) {
        return {
          x: (p.lng - self.ratioDx) / self.ratio,
          y: (-p.lat - self.ratioDy) / self.ratio,
        }
      })

      var dist = Infinity
      var bestPoint = null
      var start = self.segments.closestPointFor(self.startPos)

      function computeDistance(start, goal) {
        var path = self.segments.findPath(start, goal)
        if (!path) return Infinity
        var d = 0
        for (var i = 1; i < path.length; i++) {
          d += dist_between(path[i - 1], path[i])
        }
        return d
      }

      for (var i = 0; i < points.length; i++) {
        var p1 = points[i]
        var inter = self.segments.closestPointFor(p1)
        var d = computeDistance(start, inter) + dist_between(p1, inter) * 4
        if (d < dist) {
          dist = d
          bestPoint = inter
        }
      }
      return bestPoint && self.segments.findPath(start, bestPoint)
    }
    var testedStands = {}
    self.allStands.forEach(function (stand) {
      // console.log("check", stand)
      var layer = stand.polygon
      if (!layer) return

      var path = pathFor(layer)
      if (!path) {
        console.error("Pathfinding can't reach", stand.Exposant, stand.stands)

        if (!testedStands[stand.stands[0]]) {
          var polygon = new L.Polygon(layer._latlngs[0], errorPolygonOptions);
          self.map.addLayer(polygon);
        }
      }
      else {
        var polygon = new L.Polygon(layer._latlngs[0], validPolygonOptions);
        self.map.addLayer(polygon);
      }
      testedStands[stand.stands[0]] = true
    })
    self.allEvents.forEach(function (event) {
      // console.log("check", stand)
      if (testedStands[event.salle]) {
        return
      }
      testedStands[event.salle] = true
      var layer = event.polygon
      if (!layer) return

      var path = pathFor(layer)
      if (!path) {
        console.error("Pathfinding can't reach", event.salle)
        var polygon = new L.Polygon(layer._latlngs[0], errorPolygonOptions);
        self.map.addLayer(polygon);
      }
      else {
        var polygon = new L.Polygon(layer._latlngs[0], validPolygonOptions);
        self.map.addLayer(polygon);
      }
    })

  }

  // console.log("points", segments.points)
  // console.log("segments", segments.segments)

  function randomPath() {
    function randIdx(arr) {
      return Math.floor(Math.random() * arr.length)
    }
    var start = segments.closestPointFor(self.startPos)  //randIdx(segments.points)
    if (segments.points.length <= 4) return;
    var goal
    do {
      goal = segments.points[randIdx(segments.points)]
    } while (goal == start
    || goal.x == start.x
      || goal.y == start.y)
    self.showPath(start, goal, function () {
      setTimeout(function () {
        randomPath()
      }, 2000)
    })
    // dots.forEach(function(dot){
    // })
    // map.addLayer(markers);
    // setTimeout(function(){
    //     randomPath()
    // }, 2000)
    // var step = 0

  }
}