import "./style";
import { h, render } from "preact";
// import App from './components/app';

// export default App;

import "./spectre/spectre.less";
import firebase from "./core/firebase.js";
// import "spectre.css/dist/spectre.min.css"
import config from "./config";

import mapSvg from "!!raw-loader!./assets/maps/plan_txt.svg";
document.getElementById("text-map").innerHTML = mapSvg;

// Inject in body...

let root, keyboardRoot;
function init() {
  console.log("init sidebar");
  let Sidebar = require("./components/sidebar").default;
  root = render(
    <Sidebar />,
    document.getElementById("sidebar-container"),
    root
  );

  if (config.hasKeyboard) {
    let Keyboard = require("./components/keyboard").default;
    keyboardRoot = render(
      <Keyboard />,
      document.getElementById("keyboard-container"),
      keyboardRoot
    );
  }
}

// register ServiceWorker via OfflinePlugin, for prod only:
if (process.env.NODE_ENV === "production") {
  // require('./pwa');
}

// in development, set up HMR:
// if (module.hot) {
//   function reload() {
//     try {
//       init();
//     } catch (e) {
//       console.warn("failed to refresh, reloading");
//       location.reload();
//     }
//   }
//   //require('preact/devtools');   // turn this on if you want to enable React DevTools!
//   module.hot.accept("./components/sidebar", () =>
//     requestAnimationFrame(reload)
//   );
// }

init();
